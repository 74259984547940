import React from 'react'
import { Modal, Form, Input, Radio, Message } from 'antd'
import { Flex } from 'antd-mobile'
// import Upload from 'rc-upload'
import Upload from 'component/upload'

import { get, getStore, setStore } from 'mulan-lib'
import { processRequest } from 'lib'
import { baseApiUrl } from 'config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};

class StoreForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImgKey: '',
      currentImg: ''
    }

    this.handleCreate = this.handleCreate.bind(this)

    this.handleDetailUploaded = this.handleDetailUploaded.bind(this)
    this.handleRemoveDetailPic = this.handleRemoveDetailPic.bind(this)
  }
  handleDetailUploaded(fileList) {
    console.log('upload-change', fileList)
    if (fileList.length > 0) {
      this.setState({
        detailPic: fileList
      })
    } else {
      this.setState({
        detailPic: []
      })
    }
  }
  handleRemoveDetailPic(file) {
    const { removePicList = [] } = this.state
    removePicList.push(file)
    console.log('upload-remove', removePicList)
    this.setState({
      removePicList
    })
  }
  handleFetchPic() {
    const { record } = this.props
    const { recordId, name } = record
    if (recordId) {
      processRequest(
        get('/common/listPicture.xhtml', { relateId: recordId, tag: 'faq-detail', from: 0, max: 10 }),
        data => {
          const { picBase, picList } = data
          let detailFileList = []
          const detailPic = picList.map((o, i) => {
            const { url } = o
            detailFileList.push({
              uid: `upload-${ i }`, uploaded: true, status: 'done', name: url, url: `${ picBase }${ url }`
            })
            return `${ picBase }${ url }`
          })
          this.setState({
            detailPic,
            showUpload: true,
            detailFileList
          })
        }
      )
    } else {
      this.setState({
        detailPic: [],
        showUpload: true,
        detailFileList: []
      })
    }

  }
  componentDidMount() {
    this.handleFetchPic()
  }
  handleCommitPhoto(tag, relateId, uri) {
    processRequest(
      get('/common/commitPic.xhtml', { tag, relateId, uri }),
      data => {
        console.log(data)
      }
    )
  }
  handleDeletePhoto(fileKey) {
    processRequest(
      get('/api/admin/delPictureByFileKey', { fileKey }),
      data => {
        console.log(data)
      }
    )
  }
  handleCreate() {
    const { form, record, onCancel, origin = [] } = this.props
    const { detailPic, removePicList = [] } = this.state
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      // values.adminKey = this.state.adminKey
      values = {
        ...values,
        adminKey: this.state.adminKey,
        cate: 'tese',
        type: 'all',
      }
      get('/api/saveJiagongshang', values).then(({ code, data: replyData }) => {
        console.log(replyData)
        if (code === '9999') {
          detailPic.filter(({ uploaded }) => !uploaded).map(({ fileKey }) => {
            if (!fileKey) return
            this.handleCommitPhoto('faq-detail', values.recordId, fileKey)
          })
          removePicList.filter(({ uploaded }) => uploaded).map(({ fileKey }) => {
            this.handleDeletePhoto(fileKey)
          })
          onCancel && onCancel(true)
        }
      })
      form.resetFields();
      this.setState({ visible: false, record: {} });
    });
  }
  // handleCreate() {
  //   const { onCreate } = this.props
  //   this.setState({
  //     currentImgKey: '',
  //     currentImg: '',
  //     canSubmit: false,
  //     updatePic: false
  //   })
  //   onCreate()
  // }
  render() {
    const {
        visible, onCancel, onCreate, form,
        record = {}, basePic = ''
    } = this.props
    const { recordId = '', picUri = '', logoUrl = '' } = record
const { detailPic = [], detailFileList = [], showUpload = false } = this.state

    // console.log(record, 'record', basePic, logoUri, currentImg, currentImgKey)

    const { getFieldDecorator } = form

    return (
      <Modal
          visible={ visible }
          title={ `${ recordId ? '编辑' : '新增' }加工商`  }
          okText= '确定'
          cancelText= '取消'
          onCancel={ onCancel }
          onOk={ this.handleCreate }
        >
          <Form layout="vertical">
            <Form.Item label="公司名" { ...formItemLayout }>
              {getFieldDecorator('name', {
                initialValue: record.name || '',

                rules: [{ required: true, message: '请输入公司名!' }],
              })(
                <Input placeholder='请输入公司名' />
              )}
            </Form.Item>
            <Form.Item label="公司地址" { ...formItemLayout }>
              {getFieldDecorator('address', {
                initialValue: record.address || '',

                rules: [{ required: true, message: '请输入公司地址!' }],
              })(
                <Input placeholder='请输入公司地址' />
              )}
            </Form.Item>
            <Form.Item label="公司编号" { ...formItemLayout }>
              {getFieldDecorator('number', {
                initialValue: record.number || '',

                rules: [{ required: true, message: '请输入公司编号!' }],
              })(
                <Input placeholder='请输入公司编号' />
              )}
            </Form.Item>
            <Form.Item label="公司等级" { ...formItemLayout }>
              {getFieldDecorator('level', {
                initialValue: record.level || '',

                rules: [{ required: true, message: '请输入公司等级!' }],
              })(
                <Input placeholder='请输入公司等级' />
              )}
            </Form.Item>
            <Form.Item label="证书" { ...formItemLayout }>
              {
                showUpload && (
                  <Upload fileList={detailFileList} onRemove={ this.handleRemoveDetailPic } maxCount={ 10 } onChange={ this.handleDetailUploaded } />
                )
              }
            </Form.Item>

            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('recordId', {
                initialValue: record.recordId || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)
