import React from 'react'

let inFocus = false
let errorMessage = ''

export const iptFocus = () => {
  console.log('im focus')
  errorMessage = ''
  inFocus = true
}

export const iptBlur = () => {
  console.log('im blur')
  inFocus = false
  setTimeout(() => {
    if(inFocus == false){
      // 当input 失焦时,滚动一下页面就可以使页面恢复正常
      checkWxScroll()
    }
  }, 200)
}

const temporaryRepair = () => {
   let currentPosition,timer
   const speed=1 //页面滚动距离
   timer = setInterval(() => {
      currentPosition = document.documentElement.scrollTop || document.body.scrollTop
      currentPosition -= speed
      window.scrollTo(0, 0)  //页面向上滚动
      clearInterval(timer)
   }, 1)
}
const checkWxScroll = () => {
   const ua = navigator.userAgent.toLowerCase()
   const u = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

   if(ua.match(/MicroMessenger/i) == 'micromessenger' && !!u) { //在iphone 微信中
    temporaryRepair()
   }
}

class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleBlur = this.handleBlur.bind(this)
  }
  handleBlur(e) {
    const { onBlur } = this.props
    onBlur && onBlur(e)
    iptBlur()
  }
  render() {
    const { type = 'text', initialValue = '', value = '', className = '',  style = {}, onChange = e => e.preventDefault(), placeholder, maxLength = 999999, disable } = this.props
    // console.log(typeof value === 'function', value)
    // defaultValue={ initialValue }
    console.log(initialValue)
    // value={ typeof value === 'function' ? value() : value  }
    return (
      <input onBlur={ this.handleBlur } onFocus={ iptFocus }
             className={ className }
             type={ type }
             { ...(value ? { value } : initialValue ? { defaultValue: initialValue } : {}) }

             disable={ disable }
             style={ style }
             maxLength={ maxLength }
             onChange={ onChange }
             placeholder={ placeholder } />
    )
  }
}

export default TextInput
