import React from 'react'
import { Tag, Popconfirm, message } from 'antd'
import SetPoint from './set-point'
function cancel(e) {
  console.log(e);
}


export default ({ onUpdate, onDel, onOperate, onFetchSource }) => ([
  {
    title: 'ID',
    dataIndex: 'recordId',
    key: 'ID',
  },
  {
    title: '公司名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '公司地址',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: '公司编号',
    dataIndex: 'number',
    key: 'number'
  },
  {
    title: '等级',
    dataIndex: 'level',
    key: 'level'
  },
  {
    title: '总积分',
    dataIndex: 'point',
    key: 'point'
  },
  // {
  //   title: '证书',
  //   dataIndex: 'picUri',
  //   key: 'picUri',
  //   render: (text, { picUri }) => {
  //     const url = `http://commontemp.oss-cn-shanghai.aliyuncs.com/${ picUri }`
  //     if (!picUri) return '-'
  //     return (
  //       <a href={ url } target='_blank'><img src={ url } width='150px' /></a>
  //     )
  //   }
  // },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    render: (text, { status }) => {
      return (<span>{ status === 'N' ? '禁用' : '启用' }</span>)
    }
  },
  {
    title: '',
    key: 'action',
    render: (text, record) => (
      <span>
        <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
          <a href="#">{ record.status === 'N' ? '启用' : '禁用' }</a>
        </Popconfirm>
        <a href='#' style={{ marginRight: '12px', marginLeft: '12px' }} onClick={ () => onUpdate(record) }>编辑</a>
        <SetPoint { ...{ ...record, onFetchSource }  }  />
      </span>
    )
  }
])
