import React from 'react'
import { Carousel, Flex, Progress, Toast, Result } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import Home from 'component/home'
import TextInput from 'component/text-input'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    const companyAddress = getStore('companyAddress', 'session') || ''
    const companyInfo = getStore('companyinfo', 'session') || {}
    console.log(companyInfo, companyAddress)
    this.state = {
      api_u_key,
      companyInfo,
      name: companyInfo.name,
      mobile: companyInfo.mobile,
      address: companyAddress,
    }
    this.handlePopConfirm = this.handlePopConfirm.bind(this)
  }
  componentDidMount() {

  }
  componentWillUnmount() {
    Mask.closeAll()
  }

  handleTransProduct(o) {
    const { name, mobile, address } = this.state
    console.log(name, mobile, address)
    const { api_u_key } = this.state
    const { recordId } = o
    this.closeConfirmPop()
    this.closeInfoPop()
    processRequest(
      get('/api/tranProduct', { api_u_key, productId: recordId, address: [name, mobile, address].join('@_@') }),
      data => {
        Mask(
          <Flex align='center' justify='center' style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 99 }}>
            <div style={{ position: 'relative' }}>
              <img src={ require('img/store/dhcg.png') } style={{ width: '3rem' }} />
              <div style={{
                position: 'absolute',
                height: '.5rem',
                top: '1.4rem',
                width: '100%'
              }} onClick={ Mask.closeAll } />
              <div style={{
                position: 'absolute',
                height: '.5rem',
                width: '100%',
                bottom: '.12rem',
              }} onClick={ () => {
                Mask.closeAll()
                browserHistory.push('/store/record')
              } }/>
            </div>
          </Flex>
        )
        // console.log(data)
      }
    )

  }
  handlePopInfo(record) {
    const { name = '', mobile = '', address = '' } = this.state
    this.closeInfoPop = Mask(
      <Flex align='center' justify='center' style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 99 }}>
        <div style={{ position: 'relative' }}>
          <img src={ require('img/store/form.png') } style={{ width: '3rem' }} />
          <div style={{ position: 'absolute', top: 0, margin: '.7rem 0' }}>
            <div style={{ textAlign: 'right', width: 'calc(100% - 0.45rem)' }}>
              <TextInput initialValue={ name } style={{
                width: '1.5rem',
                height: '.25rem',
                fontSize: '.12rem',
                position: 'relative',
                backgroundColor: 'transparent',
                top: '-.08rem'
              }} onChange={ ({ currentTarget }) => this.setState({ name: currentTarget.value })  } />
              <TextInput initialValue={ mobile } type='tel' maxLength={ 11 }
              style={{
                width: '1.5rem',
                height: '.25rem',
                fontSize: '.12rem',
                position: 'relative',
                backgroundColor: 'transparent',
                top: '-.18rem'
              }} onChange={ ({ currentTarget }) => this.setState({ mobile: currentTarget.value })  } />
              <TextInput initialValue={ address } style={{
                width: '1.5rem',
                height: '.25rem',
                fontSize: '.12rem',
                position: 'relative',
                backgroundColor: 'transparent',
                top: '-0.28rem',
              }} onChange={ ({ currentTarget }) => this.setState({ address: currentTarget.value })  } />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Btn onClick={ () => this.handleTransProduct(record) }
                   style={{
                     width: '1.7rem',
                     height: '0.25rem',
                     lineHeight: '0.25rem',
                     fontSize: '0.14rem',
                     marginLeft: 'calc(50% - .85rem)',

                   }}
              >
                确定提交
              </Btn>
            </div>
          </div>

        </div>

      </Flex>
    )
  }
  handlePopConfirm() {
    const { record } = this.props
    const { companyInfo } = this.state
    const { tranPoint, title } = record
    const { point = 0 } = companyInfo
    if (point < tranPoint) {
      Toast.fail('积分不足')
      return
    }

    this.closeConfirmPop = Mask(
      <Flex align='center' justify='center' style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 99 }}>
        <div style={{ position: 'relative' }}>
          <img src={ require('img/store/confirm.png') } style={{ width: '3rem' }} />
          <Flex align='center' justify='center' style={{ width: '100%', position: 'absolute', top: '.5rem', padding: '0 .4rem', textAlign: 'center', color: '#fff' }}>
            <div style={{ fontSize: '.2rem' }}>
              愿意花费{ tranPoint }积分
              <div>兑换{ title }?</div>
            </div>
          </Flex>
          <Flex align='center' justify='between' style={{ height: '.4rem', width: '100%', position: 'absolute', bottom: '.25rem' }} >
            <div style={{ height: '100%', width: '50%' }} onClick={ () => this.handlePopInfo(record) } />
            <div style={{ height: '100%', width: '50%' }} onClick={ () => this.closeConfirmPop() }/>
          </Flex>
        </div>

      </Flex>
    )

  }
  render() {
    const { record = {} } = this.props
// console.log(bannerShow, bannerList)
    const { tranPoint, proRemainTotal, detail, detail2 } = record
    console.log(record)
    return (
        <div style={{
          color: '#fff', height: '100%',
          width: '100%',
          backgroundImage: 'url(/static/media/bg.1f3a7690.png)',
          backgroundSize: '150%',
          backgroundPosition: '-1.4rem -1.3rem',
        }}>
          <div style={{ position: 'relative' }}>
            <img src={ require('img/store/xqbg.png') } style={{width: '100%' }} />
            <div style={{ textAlign: 'center', position: 'absolute', overflow: 'hidden', top: 0, height: '1.5rem', background: 'transparent', width: 'calc(100% - .4rem)', margin: '.2rem' }}>
              <img src={ detail } style={{ width: '100%' }} />
            </div>
            <Flex style={{ height: '.5rem', padding: '0 .1rem', position: 'absolute', top: '1.74rem', width: '100%', textAlign: 'right' }} justify='between' align='center'>
              <div style={{ color: '#fff', width: '50%', paddingRight: '.1rem',fontSize: '.16rem' }}>{ tranPoint }积分</div>
              <div style={{ color: '#fff', width: '50%', paddingRight: '.1rem',fontSize: '.16rem' }}>{ proRemainTotal }件</div>
            </Flex>
            <div style={{
              position: 'absolute',
              top: '2.3rem',
              width: '100%',
              height: '2rem',
              overflow: 'scroll'
            }}>
              <img src={ detail2 } style={{ width: '100%' }} />
            </div>
            <div onClick={ this.handlePopConfirm } style={{ position: 'absolute', bottom: 0, width: '100%', height: '.3rem' }} />
          </div>
          <div>
            <Btn onClick={ () => browserHistory.goBack() }
                 style={{
                   width: '1.7rem',
                   height: '0.25rem',
                   lineHeight: '0.25rem',
                   fontSize: '0.14rem',
                   marginLeft: 'calc(50% - .85rem)',
                 }}
            >
              返回上一页
            </Btn>
            <Home />
          </div>
        </div>
    )
  }
}

export default Main
