import React from 'react'
import { Tag, Popconfirm,Select, Input, Message, Popover, Row, Button, Col, Tooltip, InputNumber } from 'antd'
import { getStore, get } from 'mulan-lib'
import { processRequest } from 'lib'
const Option = Select.Option

let sbCategories

class App extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    const { recordId } = props
    this.state = {
      recordId,
      adminKey
    }

    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.handleUpdateProduct = this.handleUpdateProduct.bind(this)
  }

  handleUpdateProduct() {
    const { adminKey, recordId, total, desc } = this.state
    // console.log(recordId, selectedCate, selectedType, selectedBrand, selectedModelType)
    if (isNaN(total)) {
      Message.error('请输入正确的数字')
      return
    }
    processRequest(
      get('/api/admin/adjustMemberPoint', {
        jiagongshangId: recordId,
        total,
        desc: desc || '系统调整',
        adminKey
      }),
      data => {
        console.log(data)
        this.setState({
          visible: false
        }, this.props.onFetchSource)
      }
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps, prevState)
    return null
  }
  componentDidMount() {

  }
  handleVisibleChange(e) {
    // console.log(e)
    if (e) {
      // this.fetchCategory()
    } else {
      this.setState({
        total: 0,
        desc: ''
      })
    }
    this.setState({
      visible: e
    })

  }
  render() {
    const { total = 0, desc = '系统调整' } = this.state
    return (
      <Popover
          title="积分调整"
          trigger="click"
          visible={ this.state.visible }
          onVisibleChange={ this.handleVisibleChange }
          content={
            <div>
              <div style={{ marginBottom: '.12rem' }}>
                <InputNumber id='overTotalModifyInput' precision={2} placeholder='积分' onChange={ val => this.setState({ total: val })  }
                initialValue={ total } style={{ width: '100%' }} />
              </div>
              <div>
                <Input placeholder='请输入描述' value={ desc } onChange={ e => this.setState({ desc: e.currentTarget.value })  }
                style={{ width: '100%' }} />
              </div>
              <div style={{ marginTop: '.12rem' }}>
                <Button style={{ width: '100%' }} type='primary' onClick={ this.handleUpdateProduct }>修改</Button>
              </div>
            </div>
          }
          defaultVisible={ false }
        >
          <a style={{ marginRight: '.12rem' }}>设置</a>
        </Popover>
      )
  }
}
export default App
