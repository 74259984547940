import React from 'react'
import { Carousel, Flex, Progress, Picker, Toast } from 'antd-mobile'
import TextInput, { iptBlur, iptFocus } from 'component/text-input'
import Upload from 'component/upload-m'

class App extends React.Component {
  constructor(props) {
    super(props)
    const { juanxinHao = '' } = this.props
    this.state = {
      juanxinHao,
      isEdit: !(juanxinHao.length > 0)
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  handleChange({ currentTarget }) {
    this.setState({
      juanxinHao: currentTarget.value
    })
  }
  handleBlur({ currentTarget }) {
    const { onSetJXH } = this.props
    this.setState({
      isEdit: false
    })
    onSetJXH && onSetJXH(currentTarget.value)

  }
  handleEdit() {
    this.setState({
      isEdit: true
    })
  }
  render() {
    const { wx, name, point, number, picUrl, handleUpload, recordId } = this.props
    const { juanxinHao, isEdit } = this.state
    // console.log(isEdit)
    return (
      <Flex justify='between' style={{ color: '#333', fontSize: '.1rem', padding: '.1rem 0', borderBottom: '.005rem solid #d2d2d2' }}>
        <span style={{ width: '.7rem' }}>{ name }</span>
        <span style={{ display: 'inline-block', width: '.3rem', textAlign: 'center', color: '#FF0000' }}>{ number }</span>
        <span style={{ display: 'inline-block', width: '.4rem', textAlign: 'center', color: '#FF0000' }}>{ point * number }</span>
        <span style={{ display: 'inline-block', width: '.5rem', textAlign: 'center', color: '#FF0000' }}>
          {
            !isEdit ? <span style={{
              width: '.5rem', display: 'inline-block', wordWrap: 'break-word'
            }} onClick={ this.handleEdit }>{ juanxinHao }</span>
                                  : <TextInput className='juanxinhao-edit'
                                               style={{
                                                 width: '100%',
                                                 border: '1px solid #ccc',
                                                 borderRadius: '.03rem',
                                                 padding: '0 .05rem'
                                               }} value={ juanxinHao } placeholder='点击输入' onChange={ this.handleChange } onBlur={ this.handleBlur } />
                        }
        </span>
        <span style={{ display: 'inline-block', width: '.5rem', textAlign: 'center', color: '#FF0000' }}>
          <Upload wx={ wx } onChange={ ({ imgKey }) => handleUpload(recordId, imgKey) }>
            <img src={ require(`img/jflr/${ picUrl ? 'ed' : 'plus' }.png`) } style={{ width: '.15rem' }} />
          </Upload>
        </span>
      </Flex>
    )
  }
}

export default App
