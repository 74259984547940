import React from 'react'
import { browserHistory } from 'react-router'

import { Layout, Menu, Breadcrumb } from 'antd';
import './admin.css'
const { Header, Content, Footer } = Layout

const { SubMenu } = Menu

export default (props => {
  const { children, location, route } = props
  const { pathname } = location
  const { path } = route

  const menuList = [
    { name: '人员管理', url: '/admin' },
    { name: '公司管理', children: [
      {
        name: '加工商', url: '/admin/company/fabricator'
      },
      {
        name: '经销商', url: '/admin/company/dealer'
      },
    ] },
    {
      name: '商城中心', children: [
        {
          name: '商品列表', url: '/admin/jf-store'
        }, {
          name: '兑换记录', url: '/admin/jf-store/records'
        }
      ]
    },
    { name: '积分记录', url: '/admin/jfrecord' },
    { name: '推荐位', url: '/admin/banner' },
    { name: '客户留言', url: '/admin/message' },
  ]
  const defaultKey = pathname.lastIndexOf('/') === pathname.length - 1 ? pathname.substr(0, pathname.length - 1) : pathname

  let breadName = ''

  menuList.forEach(({ name, url, children = [] }) => {
    if (children.length > 0) {
      children.forEach(({ url, name: name2 }) => {
        if (defaultKey === url) breadName = `${ name } / ${ name2 }`
      })
    } else {
      if (defaultKey === url) breadName = name
    }
  })

  return (
    <Layout className="layout">
        <Header style={{ backgroundColor: '#fff' }}>
          <div className="logo" style={{ marginRight: '50px', lineHeight: '64px' }}>
            <img src={ require('img/logo.png') } width='50px' />
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[ defaultKey ]}
            style={{ lineHeight: '64px' }}
          >
            {
              menuList.map(o => {
                const { name, url, children = [] } = o

                const createItem = ({ name, url }) => (
                  <Menu.Item key={ url } onClick={ () => browserHistory.push(url) }>{ name }</Menu.Item>
                )

                if (children.length > 0) {
                  return (
                    <SubMenu title={ name }>
                      {
                        children.map(o2 => createItem(o2))
                      }
                    </SubMenu>
                  )
                } else {
                  return createItem(o)
                }
              })
            }

          </Menu>
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>{ breadName }</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ background: '#fff', padding: 24, minHeight: 280, overflow:'hidden', overflowY: 'scroll' }}>
            { children }
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          OnlyEdu Co.
        </Footer>
      </Layout>
  )
})
