import React from 'react'
import { Tag, Popconfirm, message, Popover, Row, Button, Col, Tooltip, InputNumber } from 'antd'
import { Flex } from 'antd-mobile'
import { getStore } from 'mulan-lib'

function cancel(e) {
  console.log(e);
}
const create = (title, key, render) => ({
  title,
  key,
  dataIndex: key,
  render
})
const textStyle = {
  display: 'inline-block',
  width: '160px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}
const step = {
  marginRight: '.12rem'
}

export default ({ onUpdate, onExpress, onOperate, status, onModifyOverTotal, onOverTotalChange }) => {
  const adminInfo = getStore('admininfo', 'session') || {}
  const { type: adminType = 'employee' } = adminInfo
  const isAdmin = adminType === 'admin'

  const column = [
    create('ID', 'recordId'),
    create('用户', 'user', (t, { user = {} }) => {
      const { nickName = '', headUri = '', mobile = '' } = user
      return (
        <Flex justify='start' align='center'>
          <img src={ headUri } style={{ width: '.4rem', borderRadius: '.5rem',  marginRight: '.12rem' }} />
          <div>
            <div>{ nickName }</div>
            <div>{ mobile }</div>
          </div>
        </Flex>
      )
    }),
    create('商品名', 'title'),
    create('兑换积分', 'costTotal', (t, { costTotal }) => `${ costTotal }分`),
    create('兑换状态', 'orderStatus'),
    create('状态', 'expressStatus'),
    create('', 'action', (t, record) => {
      const { expressStatus } = record
      return (
        <span>
          {
            expressStatus === '未发货' && (
              <a href="#" onClick={ () => onExpress(record) }>发货</a>
            )
          }
        </span>
      )
    })
  ]
  return column
}
