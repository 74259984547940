import React from 'react'
// import { Upload: UploadComponent } from 'antd'
import { Upload, Icon, Modal } from 'antd'

import { baseApiUrl } from 'config'


class PicturesWall extends React.Component {
  constructor(props) {
    super(props)
    const { fileList = [] } = this.props
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList
    };
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleRemove(file) {
    const { onRemove } = this.props
    if (!onRemove) return

    const { uploaded, name, url, response } = file

    if (uploaded) {
      onRemove({
        fileKey: name,
        url,
        uploaded
      })
    } else if (response) {
      onRemove(response.data)
    }
  }
  handleChange = ({ fileList }) => {
    const { onChange } = this.props
    onChange(fileList.filter(o => o.status === 'done').map(o => {
      const { uploaded, name, url, response } = o
      if (uploaded) {
        return {
          fileKey: name,
          url,
          uploaded
        }
      } else if (response) {
        return response.data
      } else {
        return {}
      }
    }))
    this.setState({ fileList })
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { maxCount } = this.props
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          action={ `${ baseApiUrl }/common/uploadPic` }
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={ this.handleRemove }
        >
          {fileList.filter(o => o.status==='done').length >= maxCount ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default PicturesWall
