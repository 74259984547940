import React from 'react'
import { get, getStore, setStore, stringifyQuery } from 'mulan-lib'

import { processRequest } from 'lib'
import { browserHistory } from 'react-router'
import { Table, Row, Col, Input, message, Button, Icon, Message } from 'antd'
import createColumns from './columns'
import Upload from 'rc-upload'
import Detail from './detail'
import { baseApiUrl } from 'config'
const { Search } = Input


class App extends React.Component {
  constructor(props) {
    super(props)
    const { params } = props
    const { type = '' } = params
    const { adminKey } = getStore('admininfo', 'session') || {}
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.basePic = ''
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchName = this.handleSearchName.bind(this)
    this.handleGetStatus = this.handleGetStatus.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleExpress = this.handleExpress.bind(this)
    this.handleExport = this.handleExport.bind(this)

    const status = this.getStatus(type)

    this.state = {
      visible: true,
      adminKey,
      loading: false,
      total: 0,
      max: 99999,
      from: 0,
      visible: false,
      handleUpdate: this.handleUpdate,
      handleOperate: this.handleOperate,
    }
    this.state.columns = createColumns({
                           onUpdate: this.handleUpdate,
                           onOperate: this.handleOperate,
                           onExpress: this.handleExpress,
                         })
  }
  handleExport() {
    const { adminKey, from, max } = this.state

    window.open(`${ baseApiUrl }/api/admin/queryOrder${ stringifyQuery({
                                                                    adminKey,
                                                                    max: 9999,
                                                                    from
                                                                  }) }`)
  }
  handleExpress(record) {

    this.setState({
      detailVisible: true,
      detailRecord: record,
    })
  }
  handleGetStatus() {
    return this.state.status
  }
  getStatus(type) {
    return (type === 'storage') ? 'D' : ((type === 'uncompleted') ? 'N' : 'Y')
  }
  handleSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys })
  }

  componentDidUpdate() {
    const { canFetch } = this.state
    if (canFetch) this.fetchDataSource()
  }
  handleOperate(record, isDel) {
    const { recordId, status } = record
    const { adminKey } = this.state
    get('/api/admin/operateProduct', {
      recordId,
      status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N'),
      adminKey
    }).then(({ code, data, msg }) => {
      if(code === '9999') {
        this.fetchDataSource()
      } else {
        Message.info(msg, 10)
      }
    })
  }
  fetchDataSource(cb) {
    const { status, from, max, keyWord = '', adminKey } = this.state
    processRequest(
      get('/api/queryAllLiuyan', { from, max, adminKey }),
      data => {
        console.log(data)
        const { result, huifuMap } = data



        this.setState({
          dataSource: result.map(o => {
            const { recordId } = o
            return {
              ...o,
              key: o.recordId,
              hf: huifuMap[recordId]
            }

          }),
          loading: false,
          canFetch: false
        })
      },
      err => {
        this.setState({
          loading: false,
          canFetch: false,
          dataSource: [],
        })
        Message.info(err)
      }
    )
  }
  handleUpdate(record) {
  //   setStore('goodRecord', record, 'session')
  //   // browserHistory.push('/admin/goods/form')
  //   window.open('/admin/goods/form')
    this.setState({ visible: true, record: record });
    // ...
  }
  componentDidMount() {

    this.setState({
      loading: true
    })

    // fetch 新数据
    this.fetchDataSource()
  }
  handleAdd() {
    this.setState({ visible: true })
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, record: {} })
  }
  handleCreate() {
    const form = this.formRef.props.form;
    const { adminKey } = this.state
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      const { iconPic, detailPic, validityDate } = values
      values.startTime = validityDate[0].format('YYYY-MM-DD HH:mm:ss')
      values.endTime = validityDate[1].format('YYYY-MM-DD HH:mm:ss')
      delete values.iconPic
      delete values.validityDate
      delete values.detailPic
      // console.log(values)
      if (!values.recordId) delete values.recordId
      values = {
        ...values,
        adminKey: this.state.adminKey,
      }
      processRequest(
        get('/api/admin/saveProduct', values),
        data => {
          console.log(data)
          this.handleCommitPhoto('jf-product-icon', data, iconPic)

          if (detailPic && detailPic.length > 0) {
            detailPic.split('@_@').forEach(pic => {
              this.handleCommitPhoto('task-detail', data, pic)
            })
          }

          this.fetchDataSource()
        }
      )
      // get('/api/admin/saveTask', values).then(({ code, data: replyData }) => {
      //   console.log(replyData)
      //   if (code === '9999') {
      //
      //
      //     this.fetchDataSource()
      //   }
      // })
      form.resetFields();
      this.setState({ visible: false, teseObj: {} });
    });
  }
  handleCommitPhoto(tag, relateId, uri) {
    processRequest(
      get('/common/commitPic.xhtml', { tag, relateId, uri }),
      data => {
        console.log(data)
      }
    )
  }
  handleFilterData() {
    this.fetchDataSource()
  }
  handleSearch() {
    this.handleFilterData()
  }
  handleSearchName({ currentTarget }) {
    this.setState({
      keyWord: currentTarget.value,
    })
  }
  handleNext() {
    let { from, total, max } = this.state

    this.setState({
      from: from + max
    }, this.fetchDataSource)
  }
  handlePrev() {
    let { from, total, max } = this.state
    from -= max

    this.setState({
      from: from < 0 ? 0 : from
    }, this.fetchDataSource)
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  render() {
    const { dataSource, columns, isSearch = false, from, max, total, filterData = [], selectedRowKeys, record = {}, loading, keyWord, keyWordCate, keyWordType, keyWordBrand } = this.state
    // <Row gutter={ 16 }>
    //   <Col span={ 4 }>
    //     <Input placeholder='请输入任务名' value={ keyWord } style={{ width: '100%', marginRight: '12px' }} onPressEnter={ this.handleSearch } onChange={ this.handleSearchName } />
    //   </Col>
    // </Row>
    return (
      <div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='start' type='flex' gutter={ 16 }>
            <Col span='24' style={{ textAlign: 'right' }}>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }} />
        <Table dataSource={ isSearch ? filterData : dataSource }  pagination={{
          showSizeChanger: true,
          // showQuickJumper: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000']
        }}  loading={ loading } columns={ columns } />

        {
          this.state.detailVisible && (
            <Detail visible={ this.state.detailVisible } record={ this.state.detailRecord } onCancel={ reFetch => this.setState({ detailVisible: false }, () => { reFetch && this.fetchDataSource() }) } />
          )
        }
      </div>
    )
  }
}

export default App
