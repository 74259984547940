import React from 'react'
import { Carousel, Flex, Progress, Modal, Toast } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', boxShadow: '0px 3px 5px 0px rgba(131,103,103,0.42)', borderRadius: '.05rem' }

class Profile extends React.Component {
  constructor(props) {
    super(props)

    const companyInfo = getStore('companyinfo', 'session') || {}
    const { headimgurl } = getStore('userinfo', 'session') || {}
    const companyAddress = getStore('companyAddress', 'session') || ''
    const originCompany = getStore('originCompany', 'session') || {}
    console.log(originCompany)
    this.state = {
      companyInfo,
      headimgurl,
      companyAddress,
      originCompany,
    }
    this.handlePopEdit = this.handlePopEdit.bind(this)
  }

  componentDidMount() {}
  handlePopEdit() {
    const { originCompany } = this.state
    Modal.prompt('修改公司地址', '',
      [
        {
          text: '取消',
          onPress: value => new Promise((resolve) => {
              resolve()
          }),
        },
        {
          text: '确认修改',
          onPress: value => new Promise((resolve, reject) => {
            // Toast.info('onPress promise reject', 1);
            if (!value) {
              Toast.fail('请输入地址')
              return
            }

            const { name, level, number, recordId } = originCompany

            get('/api/saveJiagongshang', {
              recordId,
              address: value,
              name,
              level,
              number,
            }).then(({ code, data: replyData }) => {
              console.log(replyData)
              if (code === '9999') {

                this.setState({
                  companyAddress: value
                }, () => {
                  setStore('originCompany', { ...originCompany, address: value }, 'session')
                  setStore('companyAddress', value, 'session')
                  Toast.success('修改成功', 2, resolve)
                })
              }
            })
          }),
        },
      ], 'default', null, ['请输入公司地址'])
  }
  render() {
    const { companyInfo = {},  companyAddress = '无', headimgurl } = this.state

    const { cname: companyName = '', level: companyLevel = '', name: companyLoginName = '', mobile = '', point } = companyInfo
    // console.log(companyAddress)
    // <Flex align='baseline' style={{ fontSize: '.09rem' }}>
    //   还需
    //   <span style={{ fontSize: '.12rem', fontWeight: '700' }} onClick={ () => browserHistory.push('/msg') }>1000积分</span>
    //   升级至
    //   <span style={{ fontSize: '.12rem', fontWeight: '700' }}>铂金会员</span>
    // </Flex>
    return (
      <div style={{ color: '#fff', padding: '.75rem .22rem' }}>
        <Flex style={{
          padding: '.12rem .14rem',
          color: '#333',
          fontSize: '.12rem',
          background: 'rgba(255,255,255,1)',
          boxShadow: '0px 0px .19rem .03rem rgba(149,113,113,0.2)',
          borderRadius: '.05rem',
          marginBottom: '.24rem'
         }}>
          <div style={{ width: '.64rem', height: '.64rem', borderRadius: '.32rem', backgroundColor: '#eee', marginRight:'.23rem' }}>
            <img onClick={ () => browserHistory.push('/store') } src={ headimgurl } style={{ width: '100%', borderRadius: '50%' }} />
          </div>
          <div style={{ width: 'calc(100% - .87rem)' }}>
            <Flex justify='start'>
              <span style={{ fontSize: '.18rem', fontWeight: '700', marginRight: '.06rem' }}>{ companyLoginName }</span>
              <div style={{
                  width: '.64rem',
                  height: '.17rem',
                  lineHeight: '.17rem',
                  paddingLeft: '.21rem',
                  backgroundImage: `url(${ require('img/profile/crown.png') })`,
                  backgroundSize: '100% 100%',
                  color: '#fff',
                  fontSize: '.09rem',
                  position: 'relative',
                  top: '.02rem'
              }}>
                黄金会员
              </div>
            </Flex>
            <Flex justify='start' style={{ margin: '.1rem 0' }}>
              <Progress percent={40} position="normal" unfilled={false} appearTransition
                        style={{
                          display: 'inline', width: '100%', borderRadius: '.5rem',
                          backgroundColor: '#ddd',overflow: 'hidden', marginRight: '.08rem'
                        }} barStyle={{
                          border: 0,
                          background: 'linear-gradient(224deg,rgba(241,66,66,1) 0%,rgba(245,133,133,1) 100%)',
                        }} />
            </Flex>
            <Flex align='baseline' style={{ fontSize: '.09rem' }}>
              还需
              <span style={{ fontSize: '.12rem', fontWeight: '700' }} onClick={ () => browserHistory.push('/msg') }>1000积分</span>
              升级至
              <span style={{ fontSize: '.12rem', fontWeight: '700' }}>铂金会员</span>
            </Flex>
          </div>
        </Flex>
        <div style={{
          width: '3.68rem',
          height: '.815rem',
          lineHeight: '.815rem',
          paddingLeft: '.81rem',
          backgroundImage: `url(${ require('img/profile/phone.png') })`,
          backgroundSize: '100% 100%',
          color: '#000',
          fontSize: '.15rem',
          marginLeft: '-.2rem',
        }}>
          { mobile }
        </div>
        <div style={{
          width: '3.68rem',
          height: '.815rem',
          lineHeight: '.815rem',
          paddingLeft: '.81rem',
          backgroundImage: `url(${ require('img/profile/store.png') })`,
          backgroundSize: '100% 100%',
          color: '#000',
          fontSize: '.15rem',
          marginLeft: '-.2rem',
          marginTop: '-.2rem'
        }}>
          { companyName }
        </div>
        <div onClick={ this.handlePopEdit } style={{
          width: '3.68rem',
          height: '.815rem',
          lineHeight: '.815rem',
          paddingLeft: '.81rem',
          backgroundImage: `url(${ require('img/profile/location.png') })`,
          backgroundSize: '100% 100%',
          color: '#000',
          fontSize: '.15rem',
          marginLeft: '-.2rem',
          marginTop: '-.2rem'
        }}>
          { companyAddress }
        </div>
        <div onClick={ this.handlePopEdit } style={{
          width: '3.68rem',
          height: '.815rem',
          lineHeight: '.815rem',
          paddingLeft: '.81rem',
          backgroundImage: `url(${ require('img/profile/jifen.png') })`,
          backgroundSize: '100% 100%',
          color: '#000',
          fontSize: '.15rem',
          marginTop: '-.2rem',
          marginLeft: '-.2rem',
          color: '#ff4848'
        }}>
          { point }积分
        </div>

        <Btn onClick={ () => browserHistory.push('/') }>确定</Btn>
      </div>
    )
  }
}

export default Profile
