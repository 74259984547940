import React from 'react'
import { Carousel, Flex, Progress, Toast, Badge } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'

import SlidePage from 'component/slide-page'
import Detail from './detail'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)

    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key,
      list: [],
    }
  }
  fetchBanner() {
    processRequest(
      get('/api/queryRecommon', { cate: '', type: '', from: 0, max: 999, status: 'Y' }),
      data => {
        const { list } = data
        console.log(list)
        // const { list } = replyData
        this.setState({
          bannerList: list,
          bannerShow: true
        })
      }
    )
  }
  fetchIconByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-icon', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }

  fetchDetailByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchDetail2ByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail2', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }

  fetchProduct() {
    Toast.loading('加载中...')
    processRequest(
      get('/api/queryPointProduct', { from: 0, max: 3 }),
      data => {
        const { result } = data
        const ids = result.map(({ recordId }) => recordId).join(',')

        this.fetchIconByProduct(ids, ({ picBase, picList }) => {
          const productList = result.map(o => {
            const { recordId } = o
            o.icon = `${ picBase }${ picList[recordId].reverse()[0] }`
            return o
          })
          this.fetchDetailByProduct(ids, ({ picBase, picList }) => {
            const productList2 = productList.map(o => {
              const { recordId } = o
              if (picList[recordId] && picList[recordId].length > 0) {
                o.detail = `${ picBase }${ picList[recordId].reverse()[0] }`
              }
              return o
            })
            this.fetchDetail2ByProduct(ids, ({ picBase, picList }) => {
              const productList3 = productList2.map(o => {
                const { recordId } = o
                if (picList[recordId] && picList[recordId].length > 0) {
                  o.detail2 = `${ picBase }${ picList[recordId].reverse()[0] }`
                }
                return o
              })
              this.setState({
                list: productList3,
              }, Toast.hide)
            })

          })

        })

      }
    )
  }
  fetchXiaoHongDian() {
    const { api_u_key } = this.state
    processRequest(
      get('/api/hasXiaohongdian', { key: 'xhd-order', api_u_key }),
      data => {
        const { hasXiaohongdian } = data
        this.setState({
          hasXiaohongdian
        })
      }
    )
  }
  componentDidMount() {
    this.fetchProduct()
    this.fetchBanner()
    this.fetchXiaoHongDian()
  }
  handleToDetail(o) {
    Mask(<SlidePage target='right' showClose={ false }><Detail record={o} /></SlidePage>)
  }

  render() {
    const { companyInfo = {}, headimgurl, bannerShow, bannerList, isShowMenu = true, list = [], hasXiaohongdian } = this.state

    const { cname: companyName = '', level: companyLevel = '', name: companyLoginName = '' } = companyInfo
    return (
        <div style={{ color: '#fff', padding: '.33rem .27rem' }}>
          <div style={{ height: '2.09rem', marginBottom: '.24rem', position: 'relative' }}>
            {
              bannerShow && (
                <Carousel
                  autoplay={false}
                  infinite
                  beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                  afterChange={index => console.log('slide to', index)}
                  style={{ height: '2.09rem' }}
                >
                  {
                    bannerList.length > 0 && (
                      bannerList.map(o => {
                        const basePic = 'https://commonperm.oss-cn-shanghai.aliyuncs.com/'
                        return (
                          <img src={ `${ basePic }${ o.logoUri }` } style={{ width: '100%', minHeight: '2.09rem' }} onClick={ () => window.location.href = o.url } />
                        )
                      })
                    )
                  }
                </Carousel>
              )
            }

          </div>
          {
            isShowMenu && (
              <div>
                <Flex justify='between' style={{ overflow: 'initial', marginBottom: '.1rem' }}>
                  <Badge
                    dot={ hasXiaohongdian }
                    style={{
                      top: '-0.04rem',
                      right: '-0.07rem',
                      zIndex: 99,
                      border: '3px solid #fff',
                      width: '.12rem',
                      height: '.12rem',
                    }}
                  >
                    <img src={ require('img/store/wddh.png') } style={ card2Style }
                         onClick={ () => browserHistory.push('/store/record') } />
                  </Badge>
                  <img src={ require('img/store/jfdh.png') } style={ card2Style }
                       onClick={ () => browserHistory.push('/store/list') } />

                </Flex>
                <div style={{
                  width: '3.24rem',
                  // height: '1.67rem',
                  boxShadow: '0px 0px 8px 1px rgba(149,113,113,0.2)',
                  borderRadius: '5px',
                  padding: '.1rem .15rem',
                  backgroundColor: '#fff'
                }}>
                  <div style={{
                    fontSize: '.16rem',
                    fontWeight: 'bold',
                    color: 'rgba(2,2,2,1)',
                    marginBottom: '.05rem'
                  }}>热门兑换</div>
                  <Flex justify='between'>
                    {
                      list.map(o => {
                        const { icon, title, tranPoint } = o
                        return (
                          <div style={{ width: '30%' }}  onClick={ () => this.handleToDetail(o) }>
                            <div style={{ width: '.85rem', height: '.85rem', padding: '.05rem', marginBottom: '.06rem' }}>
                              <img src={ icon } style={{ width: '100%' }} />
                            </div>
                            <div style={{ fontSize: '.12rem', fontWeight: 700, color: '#000', height: '.55rem', }}>{ title }</div>
                            <div style={{ fontSize: '.1rem', fontWeight: 700, color: '#8C8C8C' }}>{ tranPoint }积分</div>
                          </div>
                        )
                      })
                    }
                  </Flex>
                </div>
              </div>
            )
          }

        </div>
    )
  }
}

export default Main
