import React from 'react'
import { Modal, Form, Input, Radio, Message } from 'antd'
import { Flex } from 'antd-mobile'
import Upload from 'rc-upload'
import { baseApiUrl } from 'config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};

class StoreForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImgKey: '',
      currentImg: ''
    }

    const _t = this
    this.handleCreate = this.handleCreate.bind(this)
    this.uploaderProps = {
      action: `${ baseApiUrl }/common/uploadPic.xhtml`,
      data: { a: 1, b: 2 },
      headers: {},
      multiple: false,
      beforeUpload(file) { console.log('beforeUpload', file.name) },
      onStart: (file) => {
        Message.loading('上传中', 0)
      },
      onSuccess({ code, data, msg }) {
        // console.log('onSuccess', data)
        if (code === '9999') {
          const { url, fileKey } = data
          const { currentImg } = _t.state

          console.log(url)
          _t.setState({
            currentImg: url,
            currentImgKey: fileKey,
            canSubmit: true,
            updatePic: true
          })

          Message.destroy()
        } else {
          console.log('upload fail')

          Message.destroy()
          Message.info(msg, 2)
        }
      },
      onProgress(step, file) { console.log('onProgress', Math.round(step.percent), file.name) },
      onError(err) { console.log('onError', err) },
    };
  }
  handleCreate() {
    const { onCreate } = this.props
    this.setState({
      currentImgKey: '',
      currentImg: '',
      canSubmit: false,
      updatePic: false
    })
    onCreate()
  }
  render() {
    const {
        visible, onCancel, onCreate, form,
        teseObj = {}, basePic = ''
    } = this.props
    const { recordId = '' } = teseObj

    const { getFieldDecorator } = form

    return (
      <Modal
          visible={ visible }
          title={ `${ recordId ? '编辑' : '新增' }经销商`  }
          okText= '确定'
          cancelText= '取消'
          onCancel={ onCancel }
          onOk={ this.handleCreate }
        >
          <Form layout="vertical">
            <Form.Item label="公司名" { ...formItemLayout }>
              {getFieldDecorator('name', {
                initialValue: teseObj.name || '',

                rules: [{ required: true, message: '请输入公司名!' }],
              })(
                <Input placeholder='请输入公司名' />
              )}
            </Form.Item>

            <Form.Item label="公司编号" { ...formItemLayout }>
              {getFieldDecorator('number', {
                initialValue: teseObj.number || '',

                rules: [{ required: true, message: '请输入公司编号!' }],
              })(
                <Input placeholder='请输入公司编号' />
              )}
            </Form.Item>

            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('recordId', {
                initialValue: teseObj.recordId || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)
