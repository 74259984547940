import React from 'react'
import { Modal, Form, Input, Radio, Message, Select, Row, Col, Button, Popconfirm } from 'antd'
import { Flex } from 'antd-mobile'
import { get, getStore, setStore } from 'mulan-lib'
import { processRequest } from 'lib'
const Option = Select.Option

function cancel(e) {
  console.log(e)
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};
const formCardItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  colon: true
};

class Detail extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    this.state = {
      adminKey
    }

    this.handleExpress = this.handleExpress.bind(this)
  }
  handleExpress() {
    const { onCancel, record } = this.props
    const { adminKey, expressNo, expressCompany } = this.state
    const { recordId } = record

    processRequest(
      get('/api/admin/opreateOrder', { orderId: recordId, expressNo: `${ expressCompany }：${ expressNo }`, adminKey }),
      data => {
        console.log(data)
        onCancel && onCancel(true)
      }
    )
  }
  componentDidUpdate() {
    // const { canFetch } = this.state
    // if (canFetch) {
    //   this.handleFetchContact()
    // }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { record: newRecord = {} } = nextProps
    const { record: oldRecord = {} } = prevState


    if (newRecord.status !== oldRecord.status || newRecord.recordId !== oldRecord.recordId) {
        console.log(newRecord, oldRecord)
      return {
        ...prevState,
        record: newRecord,
        canFetch: true
      }
    }
    return null
  }
  fetchContact() {
    const { record } = this.props
    const { user } = record
    const { recordId } = user
    get('/api/queryMiscData', { relateId: recordId, type: 'user_contact1' }).then(reply => {
      console.log(reply)
      const { data, recordId, code } = reply
      if (!code) {
        this.setState({
          ...JSON.parse(data),
          mdId: recordId,
        })
      }
    })
  }
  componentDidMount() {
    this.fetchContact()
  }
  render() {
    const {
        visible, onCancel, onCreate, basePic = ''
    } = this.props
    const { list = [], record = {}, expressNo, expressCompany } = this.state
    const { recordId, user = {}, title, otherInfo = '', status = '', address } = record
    const { nickName } = user
    const addressArr = (address || []).split('@_@')

    return (
      <Modal
          visible={ visible }
          title={ `${ title } - ${ nickName }`  }
          footer={ <Button type='ghost' onClick={ onCancel }>取消</Button> }
          onCancel={ onCancel }
        >
          <Form layout="vertical" className='edit-form employee-form' >
            <Form.Item label="收货人" { ...formItemLayout }>
              { addressArr[0] || '' }
            </Form.Item>
            <Form.Item label="联系电话" { ...formItemLayout }>
              { addressArr[1] || '' }
            </Form.Item>
            <Form.Item label="收货地址" { ...formItemLayout }>
              { addressArr[2] || '' }
            </Form.Item>

            <Form.Item label="物流单号" { ...formItemLayout }>
              <Input value={ expressNo } onChange={ ({ currentTarget }) => this.setState({ expressNo: currentTarget.value }) } />
            </Form.Item>
            <Form.Item label="物流承运商" { ...formItemLayout }>
              <Input value={ expressCompany } onChange={ ({ currentTarget }) => this.setState({ expressCompany: currentTarget.value }) } />
            </Form.Item>

            <Form.Item label=" " { ...formItemLayout }>
              <Popconfirm title="是否确认操作?" onConfirm={ this.handleExpress } onCancel={ cancel } okText="是" cancelText="否">
                <Button type='primary' style={{ marginRight: '.12rem' }}>发货</Button>
              </Popconfirm>
            </Form.Item>

          </Form>
        </Modal>
    )
  }
}

export default Form.create()(Detail)
