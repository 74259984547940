import React from 'react'
import { get, getStore } from 'mulan-lib'
import { browserHistory } from 'react-router'
import { Table, Row, Col, Input, Upload, message, Button, Icon, Message } from 'antd'
import createColumns from './columns'
import StoreForm from './form'
import CSV from 'comma-separated-values'
const { Search } = Input


class Admin extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    if (!adminKey) {
      browserHistory.push('/admin/login')
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.fetchDataSource = this.fetchDataSource.bind(this)
    this.basePic = ''

    this.state = {
      columns: createColumns({ onFetchSource: this.fetchDataSource, onUpdate: this.handleUpdate, onDel: this.handleSingleDelete,onOperate: this.handleOperate }),
      visible: false,
      adminKey,
      selectedRowKeys: []
    }
  }
  handleSelectChange(selectedRowKeys) {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  handleOperate(record, isDel) {
    const { status, recordId } = record
    const { adminKey } = this.state
    get('/api/operateJiagongshang', {
      recordId,
      adminKey,
      status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N')
    }).then(({ code, data, msg }) => {
      if(code === '9999') {
        this.fetchDataSource()
      } else {
        Message.info(msg, 10)
      }
    })

  }
  fetchDataSource(cb) {
    const { adminKey } = this.state
    get('/api/queryjiagongshang', { keyword: '' })
    .then(({ code, data: replyData }) => {
      // console.log(replyData)
      if (code === '9999') {
        const { result } = replyData
        // const { list } = replyData
        // console.log(result)
        const encoded = new CSV(result, { header: true }).encode()
        const dataBlob = new Blob([`\ufeff${encoded}`], { type: 'text/plain;charset=utf-8' });
        // console.log(window.URL.createObjectURL(dataBlob))

        this.setState({
          dataSource: result.map(o => ({ ...o, key: o.recordId })).reverse(),
          downloadUrl: window.URL.createObjectURL(dataBlob)
        })
        cb && cb()
      }
    })
  }
  handleUpdate(record) {
    console.log(record)
    this.setState({ visible: true, record: record });
    // ...
  }
  componentDidMount() {
    // fetch 新数据
    this.fetchDataSource()
  }

  handleAdd() {
    this.setState({ visible: true })
    console.log('aaa')
  }
  handleCancel(reFetch) {
    this.setState({ visible: false, record: {} }, () => {
      if (reFetch) {
        this.fetchDataSource()
      }
    })
  }
  handleCreate() {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      // values.adminKey = this.state.adminKey
      values = {
        ...values,
        adminKey: this.state.adminKey,
        cate: 'tese',
        type: 'all',
      }
      get('/api/saveJiagongshang', values).then(({ code, data: replyData }) => {
        console.log(replyData)
        if (code === '9999') {
          this.fetchDataSource()
        }
      })
      form.resetFields();
      this.setState({ visible: false, record: {} });
    });
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  handleExport() {
    const { downloadUrl } = this.state

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `加工商_${ Date.now() }.csv`;
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
  }
  render() {
    const { dataSource, columns, isSearch = false, filterData = [], selectedRowKeys, record = {}, downloadUrl  } = this.state

    return (
      <div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
            <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleAdd }>
              添加加工商
            </Button>
            <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleExport.bind(this) }>
              导出
            </Button>
          </Row>
        </div>
        <Table dataSource={ isSearch ? filterData : dataSource } columns={ columns } />
        {
          this.state.visible && (
            <StoreForm
              wrappedComponentRef={this.saveFormRef}
              basePic={ this.basePic }
              record={ record }
              visible={ this.state.visible }
              onCancel={ this.handleCancel}
              onCreate={ this.handleCreate }
            />
          )
        }

      </div>
    )
  }
}

export default Admin
