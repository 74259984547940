import React from 'react'
import { get, getStore, setStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { browserHistory } from 'react-router'
import { Table, Row, Col, Input, Switch, Message, Button, Icon } from 'antd'
import { Flex } from 'antd-mobile'
import createColumns from './columns'
import EditForm from './form'
import Upload from 'rc-upload'
import { baseApiUrl } from 'config'
const { Search } = Input


class App extends React.Component {
  constructor(props) {
    super(props)
    const { params } = props
    const { type = '' } = params
    const { adminKey } = getStore('admininfo', 'session') || {}
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.basePic = ''
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchName = this.handleSearchName.bind(this)
    this.handleGetStatus = this.handleGetStatus.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleCheckSwitch = this.handleCheckSwitch.bind(this)

    const status = this.getStatus(type)

    this.state = {
      visible: true,
      adminKey,
      loading: false,
      total: 0,
      max: 9999,
      from: 0,
      visible: false,
      handleUpdate: this.handleUpdate,
      handleOperate: this.handleOperate,
    }
    this.state.columns = createColumns({
                           onUpdate: this.handleUpdate,
                           onOperate: this.handleOperate,
                         })
  }
  handleCheckSwitch(val) {
    // console.log(val)
    const postData = {
      relateId: '001',
      type: 'store-check-switch',
      data: val ? 1 : 0,
      recordId: 12345,
    }

    processRequest(
      get('/api/saveMiscData', postData),
      data => {
        console.log(data, val)
        this.setState({
          isCheck: val ? 1 : 0
        })
        Message.success(`商城已${ val ? '关闭' : '开启' }`)
      }
    )
  }
  handleGetStatus() {
    return this.state.status
  }
  getStatus(type) {
    return (type === 'storage') ? 'D' : ((type === 'uncompleted') ? 'N' : 'Y')
  }
  handleSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys })
  }

  componentDidUpdate() {
    const { canFetch } = this.state
    if (canFetch) this.fetchDataSource()
  }
  handleOperate(record, isDel) {
    const { recordId, status } = record
    const { adminKey } = this.state
    get('/api/admin/operateProduct', {
      recordId,
      status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N'),
      adminKey
    }).then(({ code, data, msg }) => {
      if(code === '9999') {
        this.fetchDataSource()
      } else {
        Message.info(msg, 10)
      }
    })
  }
  fetchIconByTask(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-icon', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchDetailByTask(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchDetail2ByTask(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail2', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchDataSource(cb) {
    const { status, from, max, keyWord = '', adminKey } = this.state
    processRequest(
      get('/api/admin/queryProduct', { from, max, adminKey }),
      data => {
        // console.log(data)
        const { result } = data
        const ids = result.map(({ recordId }) => recordId).join(',')
        this.fetchIconByTask(ids, data => {
          console.log(data)
          const { picBase, picList } = data
          const reply = result.map(o => {
            const { recordId } = o
            const picObj = picList[recordId]
            if (picObj) {
              o.iconName = picObj.reverse()[0]
              o.iconUrl = `${ picBase }${ o.iconName }`
            }
            return o
          })
          this.fetchDetailByTask(ids, data2 => {
            const { picBase, picList } = data2
            const reply2 = reply.map(o => {
              const { recordId } = o
              const picObj = picList[recordId]
              if (picObj) {
                o.detailName = picObj.reverse()[0]
                o.detailUrl = `${ picBase }${ o.detailName }`
              }
              return o
            })
            this.fetchDetail2ByTask(ids, data3 => {
              const { picBase, picList } = data3
              const reply3 = reply2.map(o => {
                const { recordId } = o
                const picObj = picList[recordId]
                if (picObj) {
                  o.detail2Name = picObj.reverse()[0]
                  o.detail2Url = `${ picBase }${ o.detail2Name }`
                }
                return o
              })

              const llllllist = reply3.sort((a, b) => (b.recordId - a.recordId))
              this.setState({
                dataSource: llllllist.map(o => ({ ...o, key: o.recordId })),
                loading: false,
                canFetch: false
              })
            })



          })


        })

      },
      err => {
        this.setState({
          loading: false,
          canFetch: false,
          dataSource: [],
        })
        Message.info(err)
      }
    )
  }
  handleUpdate(record) {
  //   setStore('goodRecord', record, 'session')
  //   // browserHistory.push('/admin/goods/form')
  //   window.open('/admin/goods/form')
    this.setState({ visible: true, record: record });
    // ...
  }
  componentDidMount() {

    this.setState({
      loading: true
    })

    // fetch 新数据
    this.fetchDataSource()
  }
  handleAdd() {
    this.setState({ visible: true })
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, record: {} })
  }
  handleCreate() {
    const form = this.formRef.props.form;
    const { adminKey } = this.state
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      const { recordId, iconPic, detailPic, detail2Pic, validityDate, iconName, detailName, detail2Name } = values
      values.startTime = validityDate[0].format('YYYY-MM-DD HH:mm:ss')
      values.endTime = validityDate[1].format('YYYY-MM-DD HH:mm:ss')
      // if (!recordId) {
      //   values.proRemainTotal = values.proTotal
      // }
      values.newFlag = values.newFlag ? '1' : '0'
      delete values.iconPic
      delete values.iconName
      delete values.detailPic
      delete values.detail2Pic
      delete values.detailName
      delete values.detail2Name
      delete values.validityDate
      delete values.detailPic
      // console.log(values)
      if (!values.recordId) delete values.recordId
      values = {
        ...values,
        adminKey: this.state.adminKey,
      }
      processRequest(
        get('/api/admin/saveProduct', values),
        data => {
          console.log(data)
          if (iconName !== iconPic)
          this.handleCommitPhoto('jf-product-icon', data, iconPic)
          if (detailName !== detailPic)
          this.handleCommitPhoto('jf-product-detail', data, detailPic)
          if (detail2Name !== detail2Pic)
          this.handleCommitPhoto('jf-product-detail2', data, detail2Pic)
          this.fetchDataSource()
        }
      )
      // get('/api/admin/saveTask', values).then(({ code, data: replyData }) => {
      //   console.log(replyData)
      //   if (code === '9999') {
      //
      //
      //     this.fetchDataSource()
      //   }
      // })
      form.resetFields();
      this.setState({ visible: false, teseObj: {} });
    });
  }
  handleCommitPhoto(tag, relateId, uri) {
    processRequest(
      get('/common/commitPic.xhtml', { tag, relateId, uri }),
      data => {
        console.log(data)
      }
    )
  }
  handleFilterData() {
    this.fetchDataSource()
  }
  handleSearch() {
    this.handleFilterData()
  }
  handleSearchName({ currentTarget }) {
    this.setState({
      keyWord: currentTarget.value,
    })
  }
  handleNext() {
    let { from, total, max } = this.state

    this.setState({
      from: from + max
    }, this.fetchDataSource)
  }
  handlePrev() {
    let { from, total, max } = this.state
    from -= max

    this.setState({
      from: from < 0 ? 0 : from
    }, this.fetchDataSource)
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  render() {
    const { dataSource, columns, isCheck, isSearch = false, from, max, total, filterData = [], selectedRowKeys, record = {}, loading, keyWord, keyWordCate, keyWordType, keyWordBrand } = this.state
    // <Row gutter={ 16 }>
    //   <Col span={ 4 }>
    //     <Input placeholder='请输入任务名' value={ keyWord } style={{ width: '100%', marginRight: '12px' }} onPressEnter={ this.handleSearch } onChange={ this.handleSearchName } />
    //   </Col>
    // </Row>
    return (
      <div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
            <Col>


              <Flex justify='start' align='center' style={{ height: '100%', fontSize: '.12rem' }}>
                关闭商城：<Switch onChange={ this.handleCheckSwitch } checked={ isCheck == '1' } />
              </Flex>
            </Col>

            <Col>
              <Button type='primary' onClick={ this.handleAdd }>新建商品</Button>
            </Col>
          </Row>
        </div>
        <Table pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000']
        }} dataSource={ isSearch ? filterData : dataSource }  loading={ loading } columns={ columns } />

        {
          this.state.visible && (
            <EditForm
              wrappedComponentRef={this.saveFormRef}
              basePic={ this.basePic }
              record={ record }
              visible={ this.state.visible }
              onCancel={ this.handleCancel}
              onCreate={ this.handleCreate }
            />
          )
        }

      </div>
    )
  }
}

export default App
// <Row type='flex' justify='center' style={{ margin: '.3rem 0' }}>
//   <Col span={ 2 }><Button disabled={ from < max  } onClick={ this.handlePrev }>上一页</Button></Col>
//   <Col span={ 2 }><Button onClick={ this.handleNext }>下一页</Button></Col>
// </Row>
