import React from 'react'
import { Modal, Form, Input, Radio, Message, Select, Row, Col, Button, Popconfirm } from 'antd'
import { Flex } from 'antd-mobile'
import { get, getStore, setStore } from 'mulan-lib'
import { processRequest } from 'lib'
const Option = Select.Option

function cancel(e) {
  console.log(e)
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};
const formCardItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  colon: true
};

class Detail extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    this.state = {
      adminKey
    }

    this.handleExpress = this.handleExpress.bind(this)
  }
  handleExpress() {
    const { onCancel, record } = this.props
    const { adminKey, replyContent } = this.state
    const { recordId } = record
    processRequest(
      get('/api/addHuifu', { liuyanId: recordId, content: replyContent, adminKey }),
      data => {
        console.log(data)
        Message.success('回复成功')
        onCancel && onCancel(true)
      }
    )
  }
  componentDidUpdate() {
    // const { canFetch } = this.state
    // if (canFetch) {
    //   this.handleFetchContact()
    // }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { record: newRecord = {} } = nextProps
    const { record: oldRecord = {} } = prevState


    if (newRecord.status !== oldRecord.status || newRecord.recordId !== oldRecord.recordId) {
        console.log(newRecord, oldRecord)
      return {
        ...prevState,
        record: newRecord,
        canFetch: true
      }
    }
    return null
  }
  componentDidMount() {
  }
  render() {
    const {
        visible, onCancel, onCreate, basePic = '', record ={}
    } = this.props
    const { replyContent } = this.state
    const { memberName, content } = record

    return (
      <Modal
          visible={ visible }
          title={ '回复留言' }
          footer={ <Button type='ghost' onClick={ onCancel }>取消</Button> }
          onCancel={ onCancel }
        >
          <Form layout="vertical" className='edit-form employee-form' >
            <Form.Item label="用户名" { ...formItemLayout }>
              { memberName }
            </Form.Item>
            <Form.Item label="留言内容" { ...formItemLayout }>
              { content }
            </Form.Item>

            <Form.Item label="回复内容" { ...formItemLayout }>
              <Input value={ replyContent } onChange={ ({ currentTarget }) => this.setState({ replyContent: currentTarget.value }) } />
            </Form.Item>

            <Form.Item label=" " { ...formItemLayout }>
              <Popconfirm title="是否确认操作?" onConfirm={ this.handleExpress } onCancel={ cancel } okText="是" cancelText="否">
                <Button type='primary' style={{ marginRight: '.12rem' }}>回复</Button>
              </Popconfirm>
            </Form.Item>

          </Form>
        </Modal>
    )
  }
}

export default Form.create()(Detail)
