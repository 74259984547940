import React from 'react'
import { Tag, Popconfirm, message } from 'antd'

function cancel(e) {
  console.log(e);
}


export default ({ onUpdate, onDel, onOperate, onDetail }) => ([
  {
    title: 'ID',
    dataIndex: 'recordId',
    key: 'ID',
  },
  {
    title: '加工商公司名',
    dataIndex: 'jiagongshangName',
    key: 'jiagongshangName'
  },
  {
    title: '加工商员工',
    dataIndex: 'memberName',
    key: 'memberName'
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '积分',
    dataIndex: 'total',
    key: 'total'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    render: (t, { status }) => {
      return status === 'N' ? '未审核' : '已审核'
    }
  },
  // {
  //   title: '采购单',
  //   dataIndex: 'pic',
  //   key: 'pic',
  //   render: (text, { pic }) => {
  //     if (!pic) return '-'
  //     const url = `http://commontemp.oss-cn-shanghai.aliyuncs.com/${ pic }`
  //     return (
  //       <a href={ url } target='_blank'>
  //         <img src={ url } width='150px' alt='莫慌，这个测试的图片' />
  //       </a>
  //     )
  //   }
  // },
  {
    title: '',
    key: 'action',
    render: (text, record) => {
      const { status } = record
      return (
        <span>
          <a onClick={ () => onDetail(record) } style={{ marginRight: '.12rem' }}>查看明细</a>
          {
            status === 'N' && (
              <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record, 'SH') } onCancel={ cancel } okText="是" cancelText="否">
                <a href="#">审核</a>
              </Popconfirm>
            )
          }
        </span>
      )
    }
  }
])
