import { Toast } from 'antd-mobile'

export const timeout = 10 // 分钟，10分钟超时
export const processRequest = (promise, success, fail) => {
  promise.then(({ code, data, msg }) => {
    console.log(code)
    if(code === '9999') {
      success && success(data)
    } else {
      if (fail) {
        fail(msg, code)
      } else {
        Toast.fail(msg)
      }
    }
  })
}
