import React from 'react'
import { Carousel, Flex, Progress, Toast, Result } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import Home from 'component/home'
import TextInput from 'component/text-input'
import { get, setStore, getStore } from 'mulan-lib'
import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '', headimgurl } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key,
      headimgurl
    }
    this.handleSendMessage = this.handleSendMessage.bind(this)
  }
  fetchLiuyan() {
    const { message, api_u_key } = this.state

    // /api/queryMyLiuyan 查询我的留言 String api_u_key, Integer from, Integer max
    processRequest(
      get('/api/queryMyLiuyan', { api_u_key, from: 0, max: 9999 }),
      data => {
        let { result, huifuMap } = data
        result = result.sort((a, b) => {
          return (a.recordId - b.recordId)
        })
        this.setState({
          liuyan: result.map(o => {
            const { recordId } = o
            return {
              ...o,
              hf: huifuMap[recordId] || []
            }
          })
        })
      }
    )
  }
  fetchRemoveXiaoHongDian() {
    const { api_u_key } = this.state
    processRequest(
      get('/api/removeXiaohongdian', { key: 'xhz-huifu', api_u_key }),
      data => {
        console.log(data)
      }
    )
  }
  componentDidMount() {
    this.fetchLiuyan()
    this.fetchRemoveXiaoHongDian()
  }
  handleSendMessage() {
    const { message, api_u_key } = this.state

    ///api/addLiuyan 新增留言 String api_u_key, String content
    processRequest(
      get('/api/addLiuyan', { api_u_key, content: message }),
      data => {
        Toast.success('发送成功')
        this.setState({
          message: ''
        }, this.fetchLiuyan)
      }
    )
  }
  render() {
    const { message = '', liuyan = [], headimgurl } = this.state

    return (
        <div style={{ color: '#fff', height: '100%',
                      width: '100%',
                      backgroundImage: 'url(/static/media/bg.1f3a7690.png)',
                      backgroundSize: '150%',
                      backgroundPosition: '-1.4rem -1.3rem',
                    }}
        >
          <div ref={
            node => {
              if (node) {
                console.log(node.scrollHeight)
                node.scrollTop = node.scrollHeight
              }
            }
          } style={{ height: 'calc(100% - .6rem)', padding: '.3rem .16rem', overflow: 'scroll' }} >
            {
              liuyan.map(o => {
                const { memberName, content, hf } = o

                return (
                  <div>
                    <Flex justify='start' align='start' style={{ marginBottom: '.17rem' }}>
                      <div style={{ borderRadius: '100%', width: '.36rem', height: '.36rem', marginRight: '.1rem', background: '#ececec', position: 'relative' }}>
                        <img src={ headimgurl } style={{ width: '100%', borderRadius: '100%', top: '-.05rem', position: 'relative' }} />
                      </div>
                      <div style={{ padding: '.12rem .13rem', fontSize: '.12rem', color: '#333', background:'rgba(255,255,255,1)', border: '1px solid #e5e5e6', boxShadow: '0px 3px 7px 0px rgba(103,145,213,0.2)',borderRadius:'10px' }}>
                        { content }
                      </div>
                    </Flex>
                    {
                      (hf && hf.length > 0) && hf.map(o => {
                        const { content } = o
                        return (
                          <Flex justify='end' align='start' style={{ marginBottom: '.17rem' }}>
                            <div style={{ padding: '.12rem .13rem', fontSize: '.12rem', color: '#333', background:'rgba(255,255,255,1)', border: '1px solid #e5e5e6', boxShadow: '0px 3px 7px 0px rgba(103,145,213,0.2)',borderRadius:'10px' }}>
                              { content }
                            </div>
                            <div style={{ borderRadius: '100%', width: '.36rem', height: '.36rem', marginLeft: '.1rem', background: '#ececec' }}>
                              <img src={ require('img/msg/robot.png') } style={{ width: '100%', borderRadius: '100%' }} />
                            </div>
                          </Flex>
                        )
                      })
                    }
                  </div>
                )

              })
            }
          </div>
          <Flex justify='between' align='center' style={{ border: '1px solid #e5e5e6', height: '.6rem', overflow: 'hidden' }}>
            <TextInput value={ message } placeholder='请输入您的留言' onChange={ ({ currentTarget }) => this.setState({ message: currentTarget.value }) } style={{ height: '.6rem', width: 'calc(100% - .8rem)', padding: '0 .2rem', color: '#333', fontSize: '.16rem' }} />
            <img src={ require('img/msg/btn.png') } onClick={ this.handleSendMessage } style={{ width: '.8rem', height: '.6rem' }} />
          </Flex>
        </div>
    )
  }
}

export default Main
