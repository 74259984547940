import React from 'react'
import {Carousel, Flex, Progress, Toast, Badge} from 'antd-mobile'
import {browserHistory} from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import {get, setStore, getStore} from 'mulan-lib'

import {processRequest} from 'lib'
import {wx_auth} from 'config/request'
import axios from "axios";
import addVconsole from "../lib/utils/addvconsole";

const cardStyle = {width: '.75rem', boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem'}
const card2Style = {width: '1rem', borderRadius: '.05rem'}
// const card2Style = { width: '48%',  borderRadius: '.05rem' }

const stopScroll = e => {
    e.preventDefault()
}


class Main extends React.Component {
    constructor(props) {
        super(props)
        // addVconsole(true)
        const {location} = props
        const {query} = location
        const {nickname, openid, headimgurl} = query

        const {headimgurl: headimgurl2, openid: openid2, nickname: nickname2} = getStore('userinfo', 'session') || {}

        this.state = {
            openid: openid2 || openid || '',
            nickname: nickname2 || nickname || '',
            headimgurl: headimgurl2 || headimgurl || '',
            bannerShow: false
        }
        this.handleRuleClick = this.handleRuleClick.bind(this)
        this.fetchZhengshu = this.fetchZhengshu.bind(this)
    }

    // 获取API_U_KEY
    fetchUser(cb) {
        const {openid, nickname, headimgurl} = this.state
        processRequest(
            get('/api/loginByWeixinMpCode', {openId: openid}),
            data => {
                console.log(data)
                // const data = { api_u_key: 'pzc116' }
                data = {...data, openid, nickname, headimgurl}
                setStore('userinfo', data, 'session')
                this.setState(data, cb)
            },
            (msg, code) => {
                if (code === '40001' && msg === '请先关注公众号') {
                    Mask(<div style={{
                        position: 'absolute',
                        zIndex: 99,
                        width: '2rem',
                        left: 'calc(50% - 1rem)',
                        top: 'calc(50% - 1rem)',
                    }}>
                        <img src='https://mom-bullet.oss-cn-shanghai.aliyuncs.com/3M/qr.jpeg'
                             style={{border: '1px solid #000', width: '100%'}}/>
                        <div style={{
                            fontSize: '.2rem',
                            textAlign: 'center',
                            color: '#fff',
                            fontWeight: 700,
                            marginTop: '.1rem',
                        }}>长按扫描二维码关注
                        </div>
                    </div>, {
                        maskStyle: {
                            opacity: .8
                        },
                        maskClosable: false
                    })
                } else {
                    Toast.fail(msg)
                }
            }
        )
    }

    // 获取加工商信息
    fetchJGSInfo() {
        const {api_u_key} = this.state
        processRequest(
            get('/api/getLoginMemberInfo', {api_u_key}),
            data => {
                console.log(data)
                const {cname} = data
                this.setState({
                    companyInfo: data,
                    cname
                }, this.fetchZhengshu)
                setStore('companyinfo', data, 'session')
            }
        )
    }

    // 判断是否绑定过加工商
    fetchUserIsReg() {
        const {api_u_key} = this.state
        processRequest(
            get('/api/isBindJiagong', {api_u_key}),
            data => {
                const {isBind} = data

                if (!isBind) {
                    browserHistory.push('/login')
                } else {
                    this.fetchJGSInfo()
                    this.fetchXiaoHongDian()
                }
            }
        )
    }

    handleRuleClick() {
        const style = {
            position: 'absolute',
            top: '1rem'
        }
        document.body.addEventListener('touchmove', stopScroll, {passive: false})
        this.setState({
            isShowMenu: false
        })
        Mask(<PopPic pic='rule/1' suffix='png' maxWidth='100%' style={style} onClose={
            () => {
                Mask(<PopPic pic='rule/2' suffix='png' maxWidth='100%' style={style} onClose={
                    () => Mask(<PopPic pic='rule/3' maxWidth='100%' style={style} suffix='png' onClose={
                        () => {
                            document.body.removeEventListener('touchmove', stopScroll, {passive: false})
                            this.setState({
                                isShowMenu: true
                            })
                        }
                    }/>, {
                        maskStyle: {opacity: '.7'}
                    })
                }/>, {
                    maskStyle: {opacity: '.7'}
                })
            }
        }/>, {
            maskStyle: {opacity: '.7'}
        })
    }

    fetchBanner() {
        processRequest(
            get('/api/queryRecommon', {cate: '', type: '', from: 0, max: 999, status: 'Y'}),
            data => {
                const {list} = data
                console.log(list)
                // const { list } = replyData
                this.setState({
                    bannerList: list,
                    bannerShow: true
                })
            }
        )
    }

    fetchZhengshu() {
        this.fetchJGS(c => {
            console.log(c)
            if (c.length > 0) {
                const {recordId, address = ''} = c[0]
                setStore('companyAddress', address, 'session')
                setStore('originCompany', c[0], 'session')
                processRequest(
                    get('/common/listPicture.xhtml', {relateId: recordId, tag: 'faq-detail', from: 0, max: 10}),
                    data => {
                        const {picBase, picList} = data
                        const detailPic = picList.map((o, i) => {
                            const {url} = o
                            return `${picBase}${url}`
                        })
                        this.setState({
                            detailPic,
                            showZhengshu: true,
                        })
                    }
                )

            }
        })
    }

    fetchJGS(cb) {
        const {cname} = this.state
        // console.log(keyword)
        if (!cname) {
            return
        }
        const keyword = cname
        processRequest(
            get('/api/queryjiagongshang', {keyword}),
            data => {
                console.log(data)
                const {result} = data
                cb(result)
            }
        )
    }

    fetchXiaoHongDian() {
        const {api_u_key} = this.state
        processRequest(
            get('/api/hasXiaohongdian', {key: 'xhz-huifu', api_u_key}),
            data => {
                console.log(data)
                const {hasXiaohongdian} = data
                this.setState({
                    hasXiaohongdian
                })
            }
        )
    }

    handle = () => {
        const url = encodeURI('http://csd.ittabc.com')
        window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx915e0f5f377da7ef&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    }

    loadFromWeb() {
        setStore('localhref',window.location.href,'session')
        var o1 = window.location.href.split("=")
        var o2 = o1[1] ? o1[1].split("&") : ""
        var code = o2[0]
        console.log('this is code:')
        console.log(code)
        console.log('this is href:')
        console.log(window.location.href)
        if (code) {
            axios.get(`http://csd.ittabc.com/WeChatInfo.php?code=${code}`)
                .then(res => {
                    const {headimgurl, nickname, openid} = res.data
                    // console.log(headimgurl)
                    // console.log(nickname)
                    // console.log(openid)
                    this.state = {
                        openid: openid,
                        nickname: nickname,
                        headimgurl: headimgurl
                    }
                    const data = {
                        openid: openid,
                        nickname: nickname,
                        headimgurl: headimgurl,
                    }
                    setStore('userinfo', data, 'session')
                    if (!openid) {
                        console.log('no openid')
                    } else {
                        this.fetchUser(this.fetchUserIsReg)
                        this.fetchBanner()
                        this.fetchIsCheck()
                    }
                    const dataFromStore = getStore('userinfo', 'session')
                    console.log('dataFromStore')
                    console.log(dataFromStore)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    loadFromLocal() {
        const userinfo = getStore('userinfo', 'session')
        const localhref = getStore('localhref', 'session')
        console.log('userinfo')
        console.log(userinfo)
        if (userinfo == null || localhref == null) {
            this.handle()
        } else {
            const {
                openid
            }
                = userinfo
            if (openid != null) {
                this.state = userinfo
                console.log('openid')
                console.log(openid)
                if (!openid) {
                    console.log('no openid')
                } else {
                    this.fetchUser(this.fetchUserIsReg)
                    this.fetchBanner()
                    this.fetchIsCheck()
                }
            } else {
                this.handle()
            }
        }
    }

    componentDidMount() {
        const localHref = getStore('localhref','session')
        console.log('this is localHref')
        console.log(localHref)
        if (localHref != null) {
            console.log('first is not null')
            console.log(localHref)
            if (localHref == window.location.href || window.location.href == 'http://csd.ittabc.com/') {
                this.loadFromLocal()
            } else {
                this.loadFromWeb()
            }
        } else {
            console.log('first is  null')
            if (window.location.href == 'http://csd.ittabc.com/') {
                this.loadFromLocal()
            } else {
                this.loadFromWeb()
            }
        }
        /*
        if (window.location.href != 'http://csd.ittabc.com/') {
            var o1 = window.location.href.split("=")
            var o2 = o1[1] ? o1[1].split("&") : ""
            var code = o2[0]
            console.log('this is code:')
            console.log(code)
            console.log('this is href:')
            console.log(window.location.href)
            if (code) {
                axios.get(`http://csd.ittabc.com/WeChatInfo.php?code=${code}`)
                    .then(res => {
                        const {headimgurl, nickname, openid} = res.data
                        // console.log(headimgurl)
                        // console.log(nickname)
                        // console.log(openid)
                        this.state = {
                            openid: openid,
                            nickname: nickname,
                            headimgurl: headimgurl
                        }
                        const data = {
                            openid: openid,
                            nickname: nickname,
                            headimgurl: headimgurl,
                            url:window.location.href
                        }
                        setStore('userinfo', data,'session')
                        if (!openid) {
                            console.log('no openid')
                        } else {
                            this.fetchUser(this.fetchUserIsReg)
                            this.fetchBanner()
                            this.fetchIsCheck()
                        }
                        const dataFromStore = getStore('userinfo','session')
                        console.log('dataFromStore')
                        console.log(dataFromStore)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        } else {
            const userinfo = getStore('userinfo','session')
            console.log('userinfo')
            console.log(userinfo)
            if (userinfo === null) {
                this.handle()
            } else {
                const {
                    openid
                }
                    = userinfo
                if (openid != null) {
                    this.state = userinfo
                    console.log('openid')
                    console.log(openid)
                    if (!openid) {
                        console.log('no openid')
                    } else {
                        this.fetchUser(this.fetchUserIsReg)
                        this.fetchBanner()
                        this.fetchIsCheck()
                    }
                } else {
                    this.handle()
                }
            }
        }

        // const userInfo = getStore('userinfo','session')
        // if(userInfo){
        //     this.state = userInfo
        //     this.fetchUser(this.fetchUserIsReg)
        //     this.fetchBanner()
        //     this.fetchIsCheck()
        // }else{
        //     const data = {
        //         openid: "oHAzbsqHyx5AJvGcPFSCNOasdMG8",
        //         nickname: "二果",
        //         headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLhN1GvF555suPdXyibVug0GYTykbKKPRyH5OicRV8eQaicvicxVboWcQSW4CqQ1bAD0FprFK5icGzFyeg/132"
        //     }
        //     setStore('userinfo', data)
        //     this.state = data
        //     this.fetchUser(this.fetchUserIsReg)
        //     this.fetchBanner()
        //     this.fetchIsCheck()
        // }
*/
    }

    fetchIsCheck() {
        const {api_u_key} = this.state
        const postData = {
            relateId: '001',
            type: 'store-check-switch',
        }
        get('/api/queryMiscData', postData).then(reply => {
            const {data = '0', recordId, code} = reply

            if (!code) {
                this.setState({
                    isCheck: data,
                })
            } else {
                this.setState({
                    isCheck: '1'
                })
            }
        })

    }

    handlePopZhengshu() {
        const {detailPic = [], showZhengshu = false} = this.state
        Mask(
            <PopPic pic='mcsrz'>
                {
                    showZhengshu && (
                        <Carousel
                            autoplay={false}
                            infinite
                            dotStyle={{bottom: '.2rem'}}
                            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                            afterChange={index => console.log('slide to', index)}
                            style={{
                                height: '2.09rem',
                                position: 'absolute',
                                width: '2.97rem',
                                left: 'calc(50% - 1.485rem)'
                            }}
                        >
                            {
                                detailPic.length > 0 && detailPic.map(o => (
                                    <img src={o} onClick={
                                        () => {
                                            Mask(<PopPic src={o} suffix='png' maxWidth='100%'
                                                         style={{width: '100%'}}/>, {
                                                maskStyle: {
                                                    opacity: '.95'
                                                }
                                            })
                                        }
                                    } style={{width: '100%', minHeight: '2.09rem'}}/>
                                ))
                            }
                        </Carousel>
                    )
                }

            </PopPic>
            , {maskStyle: {opacity: '.85'}})
    }

    render() {
        const {
            companyInfo = {},
            headimgurl,
            bannerShow,
            bannerList,
            isShowMenu = true,
            isCheck = false,
            hasXiaohongdian
        } = this.state
        const {cname: companyName = '', level: companyLevel = '', name: companyLoginName = ''} = companyInfo

        return (
            <div style={{color: '#fff', padding: '.33rem .27rem'}}>
                <div style={{height: '2.09rem', marginBottom: '.24rem', position: 'relative'}}>
                    {
                        bannerShow && (
                            <Carousel
                                autoplay={false}
                                infinite
                                beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                                afterChange={index => console.log('slide to', index)}
                                style={{height: '2.09rem'}}
                            >
                                {
                                    bannerList.length > 0 && (
                                        bannerList.map(o => {
                                            const basePic = 'https://commonperm.oss-cn-shanghai.aliyuncs.com/'
                                            return (
                                                <img src={`${basePic}${o.logoUri}`}
                                                     style={{width: '100%', minHeight: '2.09rem'}}
                                                     onClick={() => window.location.href = o.url}/>
                                            )
                                        })
                                    )
                                }
                            </Carousel>
                        )
                    }

                </div>
                <Flex style={{
                    padding: '.12rem .14rem',
                    color: '#333',
                    fontSize: '.12rem',
                    background: 'rgba(255,255,255,1)',
                    boxShadow: '0px 0px .19rem .03rem rgba(149,113,113,0.2)',
                    borderRadius: '.05rem',
                    marginBottom: '.24rem'
                }}>
                    <div style={{
                        width: '.64rem',
                        height: '.64rem',
                        borderRadius: '.32rem',
                        backgroundColor: '#eee',
                        marginRight: '.23rem'
                    }}>
                        <img src={headimgurl} style={{width: '100%', borderRadius: '50%'}}/>
                    </div>
                    <div>
                        <div>登陆姓名：{companyLoginName}</div>
                        <div>公司名称：{companyName}</div>
                        <Flex justify='start'>会员等级：
                            <Progress percent={40} position="normal" unfilled={false} appearTransition
                                      style={{
                                          display: 'inline', width: '.74rem', borderRadius: '.5rem',
                                          backgroundColor: '#ddd', overflow: 'hidden', marginRight: '.08rem'
                                      }} barStyle={{
                                border: 0,
                                background: 'linear-gradient(224deg,rgba(241,66,66,1) 0%,rgba(245,133,133,1) 100%)',
                            }}/> {companyLevel}
                        </Flex>
                    </div>
                </Flex>
                {
                    isShowMenu && (
                        <div>
                            <Flex justify='between' style={{marginBottom: '.1rem', overflow: 'initial'}}>
                                <img src={require('img/main/sygz.jpg')} style={cardStyle}
                                     onClick={this.handleRuleClick}/>
                                <img src={require('img/main/djjs.jpg')} style={cardStyle}
                                     onClick={() => Mask(<PopPic pic='hydj'/>)}/>
                                <img src={require('img/main/mcsrz.jpg')} style={cardStyle}
                                     onClick={() => this.handlePopZhengshu()}/>
                                <img src={require('img/main/grxx.jpg')} style={cardStyle}
                                     onClick={() => browserHistory.push('/profile')}/>
                            </Flex>
                            <Flex justify='between' style={{overflow: 'initial'}}>
                                <img src={require('img/main/jflr2.png')} style={card2Style}
                                     onClick={() => browserHistory.push('/detail')}/>
                                <Badge
                                    dot={hasXiaohongdian}
                                    style={{
                                        top: '-0.04rem',
                                        right: '-0.07rem',
                                        zIndex: 99,
                                        border: '3px solid #fff',
                                        width: '.12rem',
                                        height: '.12rem',
                                    }}
                                >
                                    <img src={require('img/main/lyb.png')} style={card2Style}
                                         onClick={() => browserHistory.push('/msg')}/>
                                </Badge>
                                <img src={require('img/main/jfsc2.png')} style={card2Style}
                                     onClick={() => {
                                         if (isCheck == 1) {
                                             Toast.fail('商城未开启')
                                             return
                                         } else {
                                             browserHistory.push('/store')
                                         }
                                     }}/>
                            </Flex>
                        </div>
                    )
                }

            </div>
        )
    }
}

export default Main

// <img src={ require('img/main/jflr2.png') } style={ card2Style }
//      onClick={ () => browserHistory.push('/detail') } />
// <img src={ require('img/main/jfsc2.png') } style={ card2Style }
//      onClick={ () => {
//        if (isCheck == 1) {
//          Toast.fail('商城未开启')
//          return
//        } else {
//          browserHistory.push('/store')
//        }
//      } } />
// <img src={ require('img/main/lyb.png') } style={ card2Style }
//      onClick={ () => browserHistory.push('/msg') } />
