import React from 'react'
import { Carousel, Flex, Progress, Toast, Result } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import Home from 'component/home'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key,
    }
  }
  componentDidMount() {

  }


  render() {
    const { record = {} } = this.props
// console.log(bannerShow, bannerList)
    const { tranPoint, proRemainTotal, title, detail, address = '', expressNo } = record
    const addressArr = (address||'').split('@_@')

    return (
        <div style={{ color: '#fff', height: '100%',
        padding: '.5rem .3rem',

          color: '#fff',
          width: '100%',
          backgroundImage: 'url(/static/media/bg.1f3a7690.png)',
          backgroundSize: '150%',
          backgroundPosition: '-1.4rem -1.3rem', }}>
          <div style={{ position: 'relative' }}>
            <Flex align='center' justify='center' style={{
              width: '3.3rem',
              // padding: '.2rem',
              height: '1.54rem',
              background: '#fff',
              boxShadow: '0px 0px 19px 3px rgba(149,113,113,0.2)',
              borderRadius: '10px',
              marginBottom: '.3rem'
            }} >
              <img src={ detail } style={{ height: '100%' }} />
            </Flex>
            <div style={{
              width:'3.3rem',
              height:'2.76rem',
              boxShadow: '0px 0px 19px 3px rgba(149,113,113,0.2)',
              borderRadius: '10px',
              padding: '.25rem .35rem', color: '#333', background: '#fff', fontSize: '.14rem' }}>
              <div style={{ fontWeight: 700, fontSize: '.18rem', textAlign: 'justify' }}>您已兑换{ title }</div>
              <div style={{ padding: '.1rem 0', margin: '.1rem 0', border: '1px solid #e5e5e6', borderRight: 'none', borderLeft: 'none' }}>
                <div style={{ marginBottom: '.1rem', fontWeight: 700, fontSize: '.16rem' }}>收件信息：</div>
                <div style={{ fontSize: '.14rem' }}>
                  <div>{ addressArr[0] || '' }</div>
                  <div style={{ margin: '.06rem 0' }}>{ addressArr[1] || '' }</div>
                  <div>{ addressArr[2] || '' }</div>
                </div>
              </div>
              {
                (expressNo && expressNo.length > 0) ? (
                  <div>
                    <div style={{ margin: '0', fontWeight: 700, fontSize: '.16rem' }}>发货信息：</div>
                    <div style={{ borderRadius: '6px', padding: '.06rem 0', fontSize: '.12rem' }}>{ expressNo }</div>
                  </div>
                ) : (
                  <div style={{ marginTop: '0', fontSize: '.1rem',  color: '#7d7c7c' }}>
                    ps：工作人员将在7个工作日内进行审核发货，届时可以在此处查询快递单号，请注意查看
                  </div>
                )
              }

            </div>
          </div>
          <div>
            <Btn onClick={ () => browserHistory.goBack() }
                 style={{
                   width: '1.7rem',
                   height: '0.25rem',
                   lineHeight: '0.25rem',
                   fontSize: '0.14rem',
                   marginLeft: 'calc(50% - .85rem)',
                 }}
            >
              返回上一页
            </Btn>
            <Home />
          </div>
        </div>
    )
  }
}

export default Main
