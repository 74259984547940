import React from 'react'
import { get, getStore, stringifyQuery } from 'mulan-lib'
import { browserHistory } from 'react-router'
import { Table, Row, Col, Input, Upload, message, Button, Icon, Message } from 'antd'
import createColumns from './columns'
import StoreForm from './form'
import DetailForm from './detail'
import { baseApiUrl } from 'config'
import { processRequest } from 'lib'
const { Search } = Input


class Admin extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    if (!adminKey) {
      browserHistory.push('/admin/login')
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDetail = this.handleDetail.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleExportDetail = this.handleExportDetail.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleDetailCancel = this.handleDetailCancel.bind(this)
    this.basePic = ''

    this.state = {
      columns: createColumns({ onUpdate: this.handleUpdate, onDel: this.handleSingleDelete,onOperate: this.handleOperate, onDetail: this.handleDetail }),
      visible: false,
      adminKey,
      selectedRowKeys: []
    }
  }
  handleSearch(value = '') {
    const { dataSource = [] } = this.state
    this.setState({
      keyWord: value,
    }, this.handleFilterData)
  }
  handleFilterData() {
    const { dataSource = [], keyWord = '' } = this.state

    const filterData = dataSource.filter(o => {
      const { jiagongshangName = '' } = o
      return (jiagongshangName.indexOf(keyWord) >= 0)
    })
    // console.log(keyWord, statusFilter, this.state.isSearch, filterData)

    this.setState({
      filterData,
      isSearch: (keyWord.length > 0)
    })
  }
  handleSelectChange(selectedRowKeys) {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  handleOperate(record, isDel) {
    const { status, recordId } = record
    const { adminKey } = this.state

    if (isDel === 'SH') {
      processRequest(
        get('/api/approveJifen', { adminKey, jifenId: recordId }),
        data => {
          console.log(data)
          this.fetchDataSource()
        }
      )
      return
    }
    get('/api/operateJiagongshang', {
      recordId,
      adminKey,
      status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N')
    }).then(({ code, data, msg }) => {
      if(code === '9999') {
        this.fetchDataSource()
      } else {
        Message.info(msg, 10)
      }
    })

  }
  handleExportDetail() {
    const { adminKey, keyWord = '' } = this.state
    window.location.href = `${ baseApiUrl }/api/downloadAllJifenDetailsList${ stringifyQuery({
      from: 0, max: 10000000, adminKey
    }) }`
  }
  handleExport() {
    const { adminKey, keyWord = '' } = this.state
    window.location.href = `${ baseApiUrl }/api/downloadAllJifenList${ stringifyQuery({
      from: 0, max: 10000000, adminKey, jiagongshangName: keyWord
    }) }`
  }
  handleDetail(record) {
    const { recordId } = record
    // console.log(record)
    const { adminKey } = this.state
    get('/api/queryJifenDetais', { adminKey, jifenId: recordId }).then(({ code, data: replyData }) => {
      // console.log(replyData)
      if (code === '9999') {
        this.setState({
          detailObj: replyData,
          detailParentObj: record,
          detailVisible: true
        })
      }
    })
  }
  fetchDataSource(cb) {
    const { adminKey } = this.state
    get('/api/queryAllJifenList', { from: 0, max: 10000000, adminKey})
    .then(({ code, data: replyData }) => {
      if (code === '9999') {
        let { list } = replyData
        this.setState({ dataSource: list.map(o => ({ ...o, key: o.recordId })).reverse() })
        cb && cb()
      }
    })
  }
  handleUpdate(record) {
    console.log(record)
    this.setState({ visible: true, teseObj: record });
    // ...
  }
  handleDetailCancel() {
    this.setState({
      detailVisible: false
    })
  }
  componentDidMount() {
    // fetch 新数据
    this.fetchDataSource()
  }

  handleAdd() {
    this.setState({ visible: true })
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, teseObj: {} })
  }
  handleCreate() {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      // values.adminKey = this.state.adminKey
      values = {
        ...values,
        adminKey: this.state.adminKey,
        cate: 'tese',
        type: 'all',
      }
      get('/api/saveRecommon', values).then(({ code, data: replyData }) => {
        console.log(replyData)
        if (code === '9999') {
          this.fetchDataSource()
        }
      })
      form.resetFields();
      this.setState({ visible: false, teseObj: {} });
    });
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  render() {
    const { dataSource, columns, isSearch = false, filterData = [], selectedRowKeys, teseObj = {}, detailVisible, detailObj, detailParentObj } = this.state

    return (
      <div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
            <Col>
              <Search enterButton placeholder='请输入加工商公司' style={{ width: '300px', marginRight: '12px' }} onSearch={ this.handleSearch } />
            </Col>
            <Col span={ 6 }>
              <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleExportDetail }>
                导出全部明细
              </Button>
              <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleExport }>
                导出全部积分
              </Button>
            </Col>
          </Row>
        </div>
        <Table dataSource={ isSearch ? filterData : dataSource } columns={ columns } />
        <StoreForm
          wrappedComponentRef={this.saveFormRef}
          basePic={ this.basePic }
          teseObj={ teseObj }
          visible={ this.state.visible }
          onCancel={ this.handleCancel}
          onCreate={ this.handleCreate }
        />
        {
          detailVisible && (
            <DetailForm
              wrappedComponentRef={this.saveFormRef}
              detailObj={ detailObj }
              detailParentObj={ detailParentObj }
              visible={ detailVisible }
              onCancel={ this.handleDetailCancel}
              onDetail={ this.handleDetail }
            />
          )
        }

      </div>
    )
  }
}

export default Admin
