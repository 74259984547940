import React from 'react'
import { Carousel, Flex, Progress } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Btn from 'component/btn'
import { get, getStore } from 'mulan-lib'
import { processRequest } from 'lib'

class DetailInfo extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key
    }
  }
  fetchJFDetailList() {
    const { jifenId } = this.props
    const { api_u_key } = this.state
    processRequest(
      get('/api/queryJifenDetails', { jifenId, api_u_key, from: 0, max: 10 }),
      data => {
        console.log(data)
        const { list } = data
        this.setState({ list })
      }
    )
  }
  componentDidMount() {
    this.fetchJFDetailList()
  }
  render() {
    const { list = [] } = this.state

    return (
      <div style={{
            position: 'absolute',
            zIndex: '99',
            width: '2.75rem', minHeight: '2rem',
            left: 'calc(50% - 1.375rem)', top: '15%',
            backgroundColor: '#fff',
            boxShadow: '0px 0px 19px 3px rgba(149,113,113,0.2)',
            borderRadius: '.1rem'
      }}>
        <img src={ require('img/jfmx/mx_banner.png') } style={{ width: '100%' }} />

        <div style={{ padding: '.12rem .25rem' }}>
          <Flex justify='between' style={{ color: '#000', fontSize: '.13rem', paddingBottom: '.14rem', borderBottom: '.005rem solid #d2d2d2' }}>
            <span>产品名称</span>
            <span style={{ display: 'inline-block', width: '.5rem', textAlign: 'center' }} >总积分</span>
          </Flex>
          {
            list.length > 0 && (
              list.map(({ productName, total }) => {
                return (
                  <Flex justify='between' style={{ color: '#333', fontSize: '.1rem', padding: '.1rem 0', borderBottom: '.005rem solid #d2d2d2' }}>
                    <span>{ productName }</span>
                    <span style={{ display: 'inline-block', width: '.5rem', textAlign: 'center', color: '#FF0000' }}>{ total }</span>
                  </Flex>
                )
              })
            )
          }
        </div>
        <Btn style={{
          width: '1.56rem',
          marginLeft: 'calc(50% - .78rem)',
          marginBottom: '.22rem',
          height: '.23rem',
          lineHeight: '.23rem',
          fontSize: '.14rem',
        }} onClick={ this.props.handleContainerClose }>
          确定
        </Btn>
      </div>
    )
  }
}

export default DetailInfo
