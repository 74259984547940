import React from 'react'
import { Tag, Popconfirm, message, Popover, Row, Button, Col, Tooltip, InputNumber } from 'antd'
import { Flex } from 'antd-mobile'
import { getStore } from 'mulan-lib'

function cancel(e) {
  console.log(e);
}
const create = (title, key, render) => ({
  title,
  key,
  dataIndex: key,
  render
})
const textStyle = {
  display: 'inline-block',
  width: '160px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}
const step = {
  marginRight: '.12rem'
}

export default ({ onUpdate, onExpress, onOperate, status, onModifyOverTotal, onOverTotalChange }) => {
  const adminInfo = getStore('admininfo', 'session') || {}
  const { type: adminType = 'employee' } = adminInfo
  const isAdmin = adminType === 'admin'

  const column = [
    create('ID', 'recordId'),
    create('用户名', 'memberName'),
    create('留言', 'content'),
    create('回复', 'hf', (t, { hf = [] }) => {
      console.log(hf)
      return (
        <div>
          {
            (hf && hf.length > 0) ? (
              hf.map(o => {
                return (
                  <div>{ o.content }</div>
                )
              })
            ) : '无'
          }
        </div>
      )
    }),
    create('', 'action', (t, record) => {
      const { expressStatus } = record
      return (
        <span>
          <a href="#" onClick={ () => onExpress(record) }>回复</a>
        </span>
      )
    })
  ]
  return column
}
