import React from 'react'
import { Tag, Popconfirm, message, Popover, Row, Button, Col, Tooltip, InputNumber, Badge } from 'antd'
import { getStore } from 'mulan-lib'

function cancel(e) {
  console.log(e);
}
const create = (title, key, render) => ({
  title,
  key,
  dataIndex: key,
  render
})
const textStyle = {
  display: 'inline-block',
  width: '160px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}
const step = {
  marginRight: '.12rem'
}

export default ({ onUpdate, onOperate, status, onModifyOverTotal, onOverTotalChange }) => {
  const adminInfo = getStore('admininfo', 'session') || {}
  const { type: adminType = 'employee' } = adminInfo
  const isAdmin = adminType === 'admin'

  const column = [
    create('ID', 'recordId'),
    create('货号', 'proNumber'),
    create('商品名', 'title', (t, { newFlag, title }) => {
      return (
        <Badge count={ newFlag === '1' ? <span style={{
          background: 'red',
          color: 'rgb(255, 255, 255)',
          top: '-5px',
          right: '-15px',
          fontSize: '.11rem',
          padding: '.01rem .03rem',
          borderRadius: '10px'
        }}>New</span> : '' }>
          { title }
        </Badge>
      )
    }),
    create('类型', 'type', (t, { type }) => {
      switch (type) {
        case 'clyj': return '材料样卷'
        case 'gj': return '工具'
        case 'lw': return '3M礼物'
        case 'qt': return '其他'
        default:break;

      }
    }),
    create('兑换积分', 'tranPoint', (t, { tranPoint }) => `${ tranPoint }分`),
    create('剩余库存', 'proRemainTotal'),
    create('有效期', 'validityDate', (t, { startTime, endTime }) => (
      <div>
        <div>{ startTime } 至</div>
        <div>{ endTime }</div>
      </div>
    )),
    create('限领次数', 'tranTotal'),
    create('状态', 'status', (t, { status }) => {
      return (<span>{ status === 'N' ? '下架' : '上架' }</span>)
    }),
    create('', 'action', (t, record) => (
      <span>
        <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
          <a href="#" style={{ marginRight: '.12rem' }}>{ record.status === 'N' ? '上架' : '下架' }</a>
        </Popconfirm>
        {
          record.status === 'N' && (
            <a href="#" onClick={ () => onUpdate(record) }>编辑</a>
          )
        }

      </span>
    ))
  ]
  return column
}
