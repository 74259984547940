import React from 'react'
import { Carousel, Flex, Progress, Toast, Result } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import Home from 'component/home'
import SlidePage from 'component/slide-page'
import Detail from './detail'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key,
      type: 'clyj',
      list: []
    }
    this.fetchProduct = this.fetchProduct.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
  }

  fetchIconByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-icon', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }

  fetchDetailByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchDetail2ByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail2', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }

  fetchProduct() {
    Toast.loading('加载中...')
    const { type } = this.state
    console.log('this is type from fetchProduct of list of store')
    console.log(type)
    processRequest(
      get('/api/queryPointProduct', { from: 0, max: 99, type }),
      data => {
        console.log('this is data from fetchProduct of list of store')
        console.log(data)
        const { result } = data
        const ids = result.map(({ recordId }) => recordId).join(',')

        this.fetchIconByProduct(ids, ({ picBase, picList }) => {
          const productList = result.map(o => {
            const { recordId } = o
            if (picList[recordId] && picList[recordId].length > 0) {
              o.icon = `${ picBase }${ picList[recordId].reverse()[0] }`
            }
            return o
          })
          this.fetchDetailByProduct(ids, ({ picBase, picList }) => {
            const productList2 = productList.map(o => {
              const { recordId } = o
              if (picList[recordId] && picList[recordId].length > 0) {
                o.detail = `${ picBase }${ picList[recordId].reverse()[0] }`
              }
              return o
            })
            this.fetchDetail2ByProduct(ids, ({ picBase, picList }) => {
              const productList3 = productList2.map(o => {
                const { recordId } = o
                if (picList[recordId] && picList[recordId].length > 0) {
                  o.detail2 = `${ picBase }${ picList[recordId].reverse()[0] }`
                }
                return o
              })
              this.setState({
                list: productList3,
              }, Toast.hide)
            })
          })

        })

      }
    )
  }
  componentDidMount() {
    console.log('this is componentDidMount from list of store')
    this.fetchProduct()
  }
  handleChangeType(type) {
    Toast.loading('加载中...')
    this.setState({
      type,
      list: []
    }, this.fetchProduct)
  }
  handleToDetail(o) {
    Mask(<SlidePage target='right' showClose={ false }><Detail record={o} /></SlidePage>)
  }
  render() {
    const { list = [], type } = this.state
// console.log(bannerShow, bannerList)
    return (
        <div style={{ color: '#fff', padding: '.33rem .27rem', height: '100%' }}>
          <Flex justify='around' align='center' style={{ height: '.8rem', position: 'relative' }}>
            <img src={ require('img/store/list/clyj.png') } onClick={ () => this.handleChangeType('clyj') } style={{ width: '23%' }} />
            <img src={ require('img/store/list/gj.png') } onClick={ () => this.handleChangeType('gj') } style={{ width: '23%' }} />
            <img src={ require('img/store/list/lw.png') } onClick={ () => this.handleChangeType('lw') } style={{ width: '23%' }} />
            <img src={ require('img/store/list/qt.png') } onClick={ () => this.handleChangeType('qt') } style={{ width: '23%' }} />
          </Flex>
          <div style={{ height: 'calc(100% - 1.5rem)', overflow: 'scroll' }}>
            {
              list.length === 0 && (
                <Flex justify='center' style={{ height: '100%', width: '100%' }}><Result
                  title="暂无商品"
                  message='请下次再来'
                /></Flex>
              )
            }
            {
              list.map(o => {
                const { title, tranPoint, icon } = o
                return (
                  <div style={{ width: '50%', padding: '0 .1rem', display: 'inline-block', marginBottom: '.12rem', textAlign: 'center' }} onClick={ () => this.handleToDetail(o) }>
                    <Flex justify='center' align='center' style={{ width: '100%', height: '.85rem', margin: '.1rem 0' }}>
                      {
                        !!icon ? (<img src={icon} style={{ height: '100%' }} alt='暂无配图' />): <div style={{ fontSize: '.12rem', color: '#888' }}>暂无配图</div>
                      }
                    </Flex>
                    <div style={{ fontSize: '.12rem', fontWeight: 700, color: '#000', height: '.4rem' }}>{ title }</div>
                    <div style={{ fontSize: '.12rem', fontWeight: 700, color: '#8C8C8C' }}>{ tranPoint }积分</div>
                  </div>
                )
              })
            }
          </div>
          <div>
            <Btn onClick={ () => browserHistory.goBack() } >
              返回上一页
            </Btn>
            <Home />
          </div>
        </div>
    )
  }
}

export default Main
