import React from 'react'
import { get, getStore } from 'mulan-lib'
import { browserHistory } from 'react-router'
import { Table, Row, Col, Input, Upload, message, Button, Icon, Message } from 'antd'
import createColumns from './columns'
import StoreForm from './form'

const { Search } = Input


class Admin extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    if (!adminKey) {
      browserHistory.push('/admin/login')
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleOperate = this.handleOperate.bind(this)
    this.saveFormRef  = this.saveFormRef.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.basePic = ''

    this.state = {
      columns: createColumns({ onUpdate: this.handleUpdate, onDel: this.handleSingleDelete,onOperate: this.handleOperate }),
      visible: false,
      adminKey,
      selectedRowKeys: []
    }
  }
  handleSelectChange(selectedRowKeys) {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  handleOperate(record, isDel) {
    const { status, recordId } = record
    const { adminKey } = this.state
    get('/api/operateJiagongshang', {
      recordId,
      adminKey,
      status: isDel ? 'D' : (status === 'N' ? 'Y' : 'N')
    }).then(({ code, data, msg }) => {
      if(code === '9999') {
        this.fetchDataSource()
      } else {
        Message.info(msg, 10)
      }
    })

  }
  fetchDataSource(cb) {
    const { adminKey } = this.state
    get('/api/queryJingxiaoshang', { keyword: '' })
    .then(({ code, data: replyData }) => {
      console.log(replyData)
      if (code === '9999') {
        const { result } = replyData
        // const { list } = replyData
        this.setState({ dataSource: result.map(o => ({ ...o, key: o.recordId })).reverse() })
        cb && cb()
      }
    })
  }
  handleUpdate(record) {
    console.log(record)
    this.setState({ visible: true, teseObj: record });
    // ...
  }
  componentDidMount() {
    // fetch 新数据
    this.fetchDataSource()
  }

  handleAdd() {
    this.setState({ visible: true })
    console.log('aaa')
  }
  handleCancel() {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false, teseObj: {} })
  }
  handleCreate() {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log(values)
      // values.adminKey = this.state.adminKey
      values = {
        ...values,
        adminKey: this.state.adminKey,
        cate: 'tese',
        type: 'all',
      }
      get('/api/saveJingxiaoshang', values).then(({ code, data: replyData }) => {
        console.log(replyData)
        if (code === '9999') {
          this.fetchDataSource()
        }
      })
      form.resetFields();
      this.setState({ visible: false, teseObj: {} });
    });
  }
  saveFormRef(formRef) {
    this.formRef = formRef
  }
  render() {
    const { dataSource, columns, isSearch = false, filterData = [], selectedRowKeys, teseObj = {}  } = this.state

    return (
      <div>
        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Row justify='space-between' type='flex'>
              <Button type='primary' style={{ marginRight: '12px' }} onClick={ this.handleAdd }>
                添加经销商
              </Button>
          </Row>
        </div>
        <Table dataSource={ isSearch ? filterData : dataSource } columns={ columns } />
        <StoreForm
          wrappedComponentRef={this.saveFormRef}
          basePic={ this.basePic }
          teseObj={ teseObj }
          visible={ this.state.visible }
          onCancel={ this.handleCancel}
          onCreate={ this.handleCreate }
        />
      </div>
    )
  }
}

export default Admin
