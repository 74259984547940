import React from 'react'
import { Carousel, Flex, Progress, Toast } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Detail from './order-detail'
import SlidePage from 'component/slide-page'
import { get, setStore, getStore } from 'mulan-lib'

import { processRequest } from 'lib'
import { wx_auth } from 'config/request'

const cardStyle = { width: '.75rem',   boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)', borderRadius: '.05rem' }
const card2Style = { width: '1.54rem', borderRadius: '.05rem' }

const stopScroll = e => {
  e.preventDefault()
}


class Main extends React.Component {
  constructor(props) {
    super(props)
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      api_u_key,
    }

    this.fetchRecord = this.fetchRecord.bind(this)
  }
  fetchIconByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-icon', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }

  fetchDetailByProduct(relateIdS, cb) {
    processRequest(
      get('/common/listTagPictures.xhtml', { tag: 'jf-product-detail', relateIdS }),
      data => {
        cb && cb(data)
      }
    )
  }
  fetchRecord() {
    Toast.loading('加载中...')
    const { api_u_key } = this.state
    processRequest(
      get('/api/queryMyorder', { api_u_key, from: 0, max: 999 }),
      data => {
        const { list } = data
        console.log(list)
        const ids = list.map(({ productId }) => productId).join(',')

        this.fetchIconByProduct(ids, ({ picBase, picList }) => {
          const productList = list.map(o => {
            const { productId } = o
            o.icon = `${ picBase }${ picList[productId].reverse()[0] }`
            return o
          })
          this.fetchDetailByProduct(ids, ({ picBase, picList }) => {
            const productList2 = productList.map(o => {
              const { productId } = o
              if (picList[productId] && picList[productId].length > 0) {
                o.detail = `${ picBase }${ picList[productId].reverse()[0] }`
              }
              return o
            })
            this.setState({
              list: productList2,
            }, Toast.hide)
          })

        })

      }
    )
  }
  fetchRemoveXiaoHongDian() {
    const { api_u_key } = this.state
    processRequest(
      get('/api/removeXiaohongdian', { key: 'xhd-order', api_u_key }),
      data => {
        console.log(data)
      }
    )
  }
  componentDidMount() {
    this.fetchRecord()
    this.fetchRemoveXiaoHongDian()
  }
  handleToDetail(o) {
    Mask(<SlidePage target='right' showClose={ false }><Detail record={o} /></SlidePage>)
  }
  render() {
    const { list = [] } = this.state

    return (
        <div style={{ color: '#fff', height: '100%'}}>
          <div>
            <img src={ require('img/store/wddh-head.png') } style={{ width: '100%' }} />
          </div>
          <div style={{ height: 'calc(100% - .6rem)', overflow: 'scroll' }}>
            {
              list.map(o => {
                const { title, costTotal, icon } = o

                return (
                  <div style={{ position: 'relative' }} onClick={ () => this.handleToDetail(o) }>
                    <img src={ require('img/store/dhxq.png') } style={{ width: '100%' }} />
                    <Flex justify='start' align='center' style={{ position: 'absolute', top: 0, left: 0, margin: '0.28rem .2rem 0 0.4rem', width: 'calc(100% - .6rem)' }}>
                      <Flex align='center' style={{ height:'.7rem', width: '.7rem', margin: '.05rem 0' }}>
                        <img src={ icon } style={{ height: '100%' }} />
                      </Flex>
                      <div style={{ color: '#333', marginLeft: '.2rem' }}>
                        <div style={{ fontSize: '.13rem', marginBottom: '.06rem', width: '1.2rem', textAlign: 'justify' }}>{ title }</div>
                        <div style={{ color: '#888', fontSize: '.12rem' }}>-{ costTotal }积分</div>
                      </div>
                      <div style={{ width: '.9rem', height: '.8rem', position: 'absolute', right: 0, top: 0 }}>

                      </div>
                    </Flex>
                  </div>
                )
              })
            }

          </div>
        </div>
    )
  }
}

export default Main
