import React from 'react'
import { Modal, Form, Input, Radio, Message, Select, Row, Col, DatePicker, Switch } from 'antd'
import { Flex } from 'antd-mobile'
import Upload from 'component/upload'
import moment from 'moment'
// import brandList from './brand'
import { get, getStore, setStore } from 'mulan-lib'
import { processRequest } from 'lib'
import { baseApiUrl } from 'config'

const { RangePicker } = DatePicker
const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};
const formCardItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  colon: true
};

class StoreForm extends React.Component {
  constructor(props) {
    super(props)
    const { adminKey } = getStore('admininfo', 'session') || {}
    const { record = {} } = props
    const { iconName = '' } = record
    this.state = {
      currentImgKey: '',
      currentImg: '',
      adminKey,
      iconPic: iconName
    }

    const _t = this
    this.handleCreate = this.handleCreate.bind(this)
    this.handleIconUploaded = this.handleIconUploaded.bind(this)
    this.handleDetailUploaded = this.handleDetailUploaded.bind(this)
    this.handleDetail2Uploaded = this.handleDetail2Uploaded.bind(this)
  }
  handleCreate() {
    const { onCreate } = this.props
    this.setState({
      currentImgKey: '',
      currentImg: '',
      canSubmit: false,
      updatePic: false
    })
    onCreate()
  }
  componentDidMount() {}
  handleIconUploaded(fileList) {
    console.log('fileList', fileList)
    if (fileList.length > 0) {
      const icon = fileList[0]
      this.setState({
        iconPic: icon.fileKey
      })
    } else {
      this.setState({
        iconPic: null
      })
    }
  }
  handleDetail2Uploaded(fileList) {
    console.log('fileList', fileList)
    if (fileList.length > 0) {
      const detail = fileList[0]
      this.setState({
        detail2Pic: detail.fileKey
      })
    } else {
      this.setState({
        detail2Pic: null
      })
    }
  }
  handleDetailUploaded(fileList) {
    console.log('fileList', fileList)
    if (fileList.length > 0) {
      const detail = fileList[0]
      this.setState({
        detailPic: detail.fileKey
      })
    } else {
      this.setState({
        detailPic: null
      })
    }
  }
  render() {
    const {
      visible, onCancel, onCreate, form,
      record = {}, basePic = ''
    } = this.props
    const { taskRuleList = [], iconPic, detailPic, detail2Pic } = this.state
    const { recordId, iconUrl, iconName, detailName, detailUrl, detail2Name, detail2Url } = record
    let iconFileList = []
    let detailFileList = []
    let detail2FileList = []
    if (recordId) {
      iconFileList = [{  uid: '-1', status: 'done', name: iconName, url: iconUrl }]
    }
    if (recordId) {
      detailFileList = [{  uid: '-1', status: 'done', name: detailName, url: detailUrl }]
    }
    if (recordId) {
      detail2FileList = [{  uid: '-1', status: 'done', name: detail2Name, url: detail2Url }]
    }

    const { getFieldDecorator } = form
// console.log(iconUrl, iconName)
    return (
      <Modal
          visible={ visible }
          title={ `${ recordId ? '编辑' : '新增' }商品`  }
          okText= '确定'
          cancelText= '取消'
          maskClosable={ false }
          onCancel={ onCancel }
          onOk={ this.handleCreate }
        >
          <Form layout="vertical" className='edit-form employee-form' >
            <Form.Item label="商品名称" { ...formItemLayout }>
              {getFieldDecorator('title', {
                initialValue: record.title || '',
                rules: [{ required: true, message: '请输入产品名称!' }],
              })(
                <Input placeholder='请输入产品名称' />
              )}
            </Form.Item>
            <Form.Item label="货号" { ...formItemLayout }>
              {getFieldDecorator('proNumber', {
                initialValue: record.proNumber || '',
                rules: [{ required: true, message: '请输入货号!' }],
              })(
                <Input placeholder='请输入货号' />
              )}
            </Form.Item>
            <Form.Item label="产品图" { ...formItemLayout }>
              <Upload fileList={ iconFileList } maxCount={ 1 } onChange={ this.handleIconUploaded } />
              {getFieldDecorator('iconPic', {
                initialValue: iconPic,
              })(<Input type='hidden' />)}
            </Form.Item>
            <Form.Item label="Banner图" { ...formItemLayout }>
              <Upload fileList={ detailFileList } maxCount={ 1 } onChange={ this.handleDetailUploaded } />
              {getFieldDecorator('detailPic', {
                initialValue: detailPic,
              })(<Input type='hidden' />)}
            </Form.Item>
            <Form.Item label="详情图" { ...formItemLayout }>
              <Upload fileList={ detail2FileList } maxCount={ 1 } onChange={ this.handleDetail2Uploaded } />
              {getFieldDecorator('detail2Pic', {
                initialValue: detail2Pic,
              })(<Input type='hidden' />)}
            </Form.Item>
            <Form.Item label="兑换积分" { ...formItemLayout }>
              {getFieldDecorator('tranPoint', {
                initialValue: record.tranPoint || 0,
                rules: [{ required: true, message: '请输入兑换积分!' }],
              })(
                <Input placeholder='请输入兑换积分' />
              )}
            </Form.Item>
            <Form.Item label="商品类型" { ...formItemLayout }>
              {getFieldDecorator('type', {
                initialValue: record.type || undefined,
                rules: [{ required: true, message: '请输入商品类型!' }],
              })(
                <Select placeholder='请选择商品类型'>
                  {
                    [
                      { name: '材料样卷', value: 'clyj' },
                      { name: '工具', value: 'gj' },
                      { name: '3M礼物', value: 'lw' },
                      { name: '其他', value: 'qt' },
                    ].map(({name, value}) => {
                      return (<Option key={ value } value={ value } >{name}</Option>)
                    })
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label="库存" { ...formItemLayout }>
              {getFieldDecorator('proTotal', {
                initialValue: record.proTotal || 0,
                rules: [{ required: true, message: '请输入可兑换总数!' }],
              })(
                <Input placeholder='请输入可兑换总数' { ...(!!!recordId ? {} : { disabled: true }) } />
              )}
            </Form.Item>
            <Form.Item label="剩余库存" { ...formItemLayout }>
              {getFieldDecorator('proRemainTotal', {
                initialValue: record.proRemainTotal || 0,
                rules: [{ required: true, message: '请输入剩余库存!' }],
              })(
                <Input placeholder='请输入剩余库存，首次添加与库存保持一致' />
              )}
            </Form.Item>

            <Form.Item label="月兑换上限" { ...formItemLayout }>
              {getFieldDecorator('tranTotal', {
                initialValue: record.tranTotal || 1,
                rules: [{ required: true, message: '请输入用户兑换次数!' }],
              })(
                <Input placeholder='请输入用户兑换次数' />
              )}
            </Form.Item>

            <Form.Item label="有效期" { ...formItemLayout }>
              {getFieldDecorator('validityDate', {
                initialValue: [moment(record.startTime || Date.now()), moment(record.endTime || Date.now())],
                rules: [{ required: true, message: '请输入有效期!' }],
              })(
                <RangePicker onChange={ (val, dateStr) => console.log(val, dateStr) } />
              )}
            </Form.Item>

            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('iconName', {
                initialValue: record.iconName || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>
            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('recordId', {
                initialValue: record.recordId || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>

          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)

// <Form.Item label="是否新品" { ...formItemLayout }>
//   {getFieldDecorator('newFlag', {
//     valuePropName: 'checked',
//     initialValue: record.newFlag === '1'
//   })(<Switch />)}
// </Form.Item>
